import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/services/est-banner.jpg'
import est from '../../images/services/icons/est-icon.png'
import rocket from '../../images/services/rocket.PNG'

export default function EST() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1 className='center'><Image src={est} className='icon' alt='a graphic of a laptop with an airplane on the screen' />Engineering Services Team</h1>
                            <h2>Providing on demand support for exercises</h2>
                        </div>
                        <Image src={header} className='banner' width='1200' height='400'  fluid alt='a ship in the middle of the ocean' />

                        <Container>
                            <Row className='paddingTop'>
                                <Col sm={12}>
                                    <p>
                                        The Engineering Services Team (EST) provides exercise and live-fire support to the Missile Defense Agency (MDA) and Combatant Command (COCOM) organizations. EST duties include assisting in designing wargames and TTXs, developing Tactical Data Link (TDL) architecture for testing and executing (if requested), modeling realistic and currently operationally implemented information exchanges and data forwarding (if requested), developing and testing TDL objectives, reporting identified TDL and Military Standard (MIL-STD) issues for each unit in an event, reporting TDL architecture deficiencies, and providing troubleshooting of TDL network issues, and providing results to the Joint Interface Control Officer (JICO), Event Lead, and/or Test Director.
                                    </p>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Image fluid className='greyBackground' width='500' height='300' src={rocket} alt='A rocket launching'/>
                                    <p className='caption'>An exercise testing a MIM-104 Patriot with a live rocket</p>
                                </Col>
                                <Col xs={12} md={6}>
                                    <p>
                                        As part of the EST planning function, the team may assist a JICO with TDL connectivity, network, OPTASK link development, or Common Operational Picture (COP) troubleshooting, create and tailor Exercise OPTASK Link instead of existing documentation, create Active TADIL Desktop (ATD) displays for viewing by personnel as a replication of architecture, develop TDACS connections, routing, monitoring, and data collection plan for event test and execution, review test plans, provide TDACS/Link 16/Voice/COP input during their development, and attend event meetings and conferences as requested.
                                    </p>
                                {/* </Col>
                                <Col sm={12} md={12}> */}
                                    <p>
                                        As part of the EST exercise participation functions, they monitor modeling & simulation architecture and report identified issues, monitor event network for issues and/or latency as requested, analyze test and execution results of identified issues, archive test results via numerous methods for input into the test reports and perform data recording in various forms for customers as requested.
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={12}>
                                    <p>
                                        As part of the ESTs work with MDA test equipment, they report to MDA organizational lead the Tactical Data Analysis and Connectivity System (TDACS) utilization and availability for assigned exercises, perform monthly or situational Information Assurance (IA) updates to MDA and other TDACS as requested, perform DTWX TDACS software upgrades, RSA, and other required cyber software patching to ensure TDACS meets cyber/IA requirements, helps in the documentation required for Risk Management Framework (RMF) Authority to Operate (ATO) Security packages, submit Change Request for DTWX TDACS upgrades or modifications and coordinate and maintain TDACS hard drive inventory.
                                    </p>
                                </Col>
                                <Col xs={12} sm={12} md={12}>
                                    <p>
                                        Upon purchase of TDACS turnkey systems, The EST delivers TDACS operator and/or maintenance training to our various customers. EST members also provide inputs for TDACS improvements, discrepancies, and operator manual updates to the Märzen Product Development Team (PDT) and Support Services Team (SST).
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}