import React, { useState, useEffect } from 'react'
import { Container, Spinner, Image, Table, Row, Col } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/products/iff-banner.jpg'
import iff from '../../images/products/IFF-workstation.jpg'
import icon from '../../images/services/icons/cpt-icon.png'

export default function IFF() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1><Image src={icon} className='icon' alt='a graphic of a blue monitor with a picture of lines connected on the monitor' />IFF:</h1>
                            <h2>Intermediate Form Factor</h2>
                        </div>
                        <Image src={header} fluid width='1200' height='400' className='banner' alt='Blue fades in on the sides with an IFF in the middle' />
                        <Container>
                            <Row className='paddingTop'>
                                <Col sm={12} md={6}>
                                    <p>
                                        The Intermediate Form Factor (IFF) is the most common TDACS form factor. The IFF is for those users who require the full functionality of a dual display TDACS but also have portability and small footprint requirements. The IFF has been used in numerous national and international exercises and events that require full TDACS functionality in temporary command center locations.
                                    </p>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className='greyBackground'>
                                        <Image src={iff} width='600px' height='400px' fluid className='center' alt='the side view of an IFF' />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Tech Specs:</th>
                                                <th>Form Factor</th>
                                                <th>Monitor(s)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Overall Length</td>
                                                <td>15.5"</td>
                                                <td>20"</td>
                                            </tr>
                                            <tr>
                                                <td>Overall Width</td>
                                                <td>10.4"</td>
                                                <td>3"</td>
                                            </tr>
                                            <tr>
                                                <td>Overall Height</td>
                                                <td>8.4" (4U)</td>
                                                <td>18"</td>
                                            </tr>
                                            <tr>
                                                <td>Weight (lbs.)</td>
                                                <td>24.5 lbs</td>
                                                <td>N/A</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs={12}>
                                    <Table striped bordered responsive='sm'>
                                        <thead>
                                            <tr>
                                                <th>Characteristic</th>
                                                <th colSpan={2}>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Power Requirements</td>
                                                <td>1 Three-Pronged 120 VAC outlet (IFF)</td>
                                                <td>2 Three-Pronged 120 VAC outlet (Monitors)</td>
                                            </tr>
                                            <tr>
                                                <td>Power Consumption</td>
                                                <td>Idle: 66.3 Watts, 226.1 BTU/hr</td>
                                                <td>Max: 286.3 Watts, 976.5 BTU/hr</td>
                                            </tr>
                                            <tr>
                                                <td>Temperature</td>
                                                <td>Operating: 32º - 104ºF</td>
                                                <td>Operating: 0º - 40ºC</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}