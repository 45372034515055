import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/services/west-banner.jpg'
import west from '../../images/services/icons/west-icon.png'
import hardware from '../../images/services/WEST-Hardware.JPG'
import software from '../../images/services/WEST-Software.JPG'
import security from '../../images/services/WEST-Security.JPG'

export default function WEST() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1><Image src={west} className='icon' alt='a graphic of a headset with a gear' /> Windows Enterprise Support Team</h1>
                            <h2>Consulting support</h2>
                        </div>
                        <Image src={header} width='1200' height='400' fluid className='banner' alt='A closeup of fiber optic cable' />
                        <Container>
                            <Row className='paddingTop'>
                                <Col>
                                    <p>
                                        Märzen's core competencies and consulting activities in Software Engineering are centered on the Microsoft Windows 10 and Server Platforms. We specialize in the development of enterprise architecture solutions and are experienced in the design, testing, deployment, and help desk support for Microsoft products in DOD environments.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        On Windows platforms, Märzen provides System Engineering and Consulting support to both Military and Commercial customers with a special focus on Hardware Specifications, Software Development, and System Security support.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} xl={4}>
                                    <h2 className='h2West'>Software</h2>
                                    <div className='center'>
                                        <Image src={software} width='600px' height='400px' className='center' alt='A PFF with TDACS software on the screen' fluid />
                                    </div>
                                    <p>
                                        Software - Märzen has extensive expertise in the development of object-oriented systems and application software targeting both the Win32 and Win64 platforms. A core competency at Märzen is object-oriented software design for the Windows platform with a special emphasis on networking, real-time tactical communications, DirectX, and XML data exchange.
                                    </p>
                                </Col>
                                <Col xs={12} xl={4}>
                                    <h2 className='h2West'>Hardware</h2>
                                    <div className='center'>
                                        <Image src={hardware} width='600px' height='400px' alt='A technician installing hard drives into cages' fluid />
                                    </div>
                                    <p>
                                        Hardware - Märzen specializes in the development of hardware specifications for handheld, laptop, workstation, and server platforms to be integrated into the customer's overall enterprise.
                                    </p>
                                </Col>
                                <Col xs={12} xl={4}>
                                    <h2 className='h2West'>Security</h2>
                                    <div className='center'>
                                        <Image src={security} width='600px' height='400px' alt='An engineer writing code' fluid />
                                    </div>
                                    <p>
                                    Security - Märzen also specializes in the development of high-security configurations and enterprise-ready scripts that control the installation and security of both operating systems and applications.Many of Märzen’s customers must operate on DOD Classified networks.Märzen developed installation software, scripts, tools, and supporting documentation that is used operationally in classified DOD facilities worldwide.In this area, Märzen now provides a monthly service of IA updates configured to meet both TDACS and DOD STIG requirements.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}