import React, { useState, useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

export default function Footer() {

    let currentYear = new Date().getFullYear()

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <div className='mt-auto'>
            {loading === false ? (
                <animated.div style={fade}>
                    <footer>
                        <h1 id='h1-footer'>Märzen Group LLC</h1>
                        <div id='row'>
                            <a href='/'>Home</a>
                            <a href='/about'>About Us</a>
                            <a href='/employment'>Careers</a>
                            <a href='/products'>Products</a>
                            <a href='/services'>Services</a>
                        </div>
                        {/* <a href='/about'>Privacy Policy</a> */}
                        {/* <a href='/about'>Terms and Conditions</a> */}
                        {/* <a href='/about'> Our Disclaimer</a> */}
                        <p id='copyright'>Copyright© 1999 - {currentYear} Märzen Group LLC. All rights reserved. Revised: October 1, 2022.</p>
                    </footer>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </div>
    )
}