import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image, Table, Spinner } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import Theodore from '../../images/theodore.jpg'

export default function Labor() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1>Märzen - Seaport-NxG Contract Information</h1>
                        </div>
                        <Container>
                            <Row className='paddingTop'>
                                <Col xs={12} med={12}>
                                    <p>On 02 January 2019, the Naval Surface Warfare Center (NSWC) Dahlgren Division awarded Märzen Group LLC a contract under the Seaport Enhanced Next Generation (SeaPort-NxG) Rolling Admissions. Contract number N00178-19-D-8079 allows Marzen Group to provide Engineering, Technical and Programmatic Support Services for various Naval Program Executive Offices, Directorates, and field activities.  In response to task orders issued under this contract by the Naval Sea Systems Command, Space and Naval Warfare Systems Command, Naval Supply Systems Command, Military Sealift Command, Naval Facilities Command, Office of Naval Research, or the United States Marine Corps, the Contractor shall provide services that potentially span the entire spectrum of mission areas and technical capabilities supported by the Department of the Navy (DON) ordering activities. Services within the 2 Categories (Engineering Services and Program Management Services) with 23 functional area subcategories identified below may be performed under this contract for new and existing product areas, programs, or missions, which are assigned to these activities during the life of the contract.  SeaPort-NxG does not allow for the direct procurement of supplies or hardware. Any material or products ordered shall be incidental and in direct support of performed services (for example, small scale testing equipment, prototypes, or spares.) </p>
                                    <hr />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6}>
                                    <h2>Märzen - Seaport Related Experience</h2>
                                    <p>Märzen's professional staff provide consulting services and products to government and commercial customers around the world. Märzen’s core competencies and consulting experience related to the Seaport contract are centered on our subject matter expertise with US Navy combat systems, US and NATO Multi-TADIL Network Architectures, Systems Analysis, and Interoperability Testing, and Large Scale Multinational Exercise Support and Training.</p>
                                    <p>Specifically, Märzen subject matter experts [SMEs] have extensive real-world experience with:</p>

                                    <ul>
                                        <li>US Navy combat systems operations and train</li>
                                        <li>Tactical air and missile defense interoperability test, training, and operations</li>
                                        <li>Multi-TADIL Data Link communications test, training, and operations</li>
                                        <li>Specialized tactical protocol requirements, test analysis, and training</li>
                                        <li>Communication architectures analysis for national/international exercises</li>
                                        <li>Designing interoperability test plans/procedures/experiments</li>
                                        <li>Software test tool requirements, development, and test</li>
                                        <li>System testing direction, data analysis, and reporting</li>
                                        <li>Briefing senior executives on topics of interest</li>
                                    </ul>
                                </Col>
                                <Col xs={12} md={6}>
                                    <Image height='600' width='600' className='greyBackground' src={Theodore} alt='The dry cargo and ammunition ship, USNS Matthew Perry (T-AKE 9), comes alongside the aircraft carrier USS Theodore Roosevelt (CVN 71) during a replenishment-at-sea' fluid />
                                    <p className='caption'>"The appearance of U.S. Department of Defense (DoD) visual information does not imply or constitute DoD endorsement." Photo courtesy of US Navy, Petty Officer 3rd Class Andrew Langholf, "USS Theodore Roosevelt (CVN 71) Deployment FY 2018", 2018. No Copyright, See Commons: <a href='https://creativecommons.org/licenses/by-nd/1.0/legalcode'>Licensing</a>, <a href='https://www.dvidshub.net/image/4246203/uss-theodore-roosevelt-cvn-71-deployment-fy-2018'>Source</a></p>
                                    <p>
                                        Märzen provides support to commercial, non-profit, and government organizations in such diverse areas as the development of real-time, object-oriented software and SQL databases for tactical military communications, the development of geo-reference graphics display, analysis of network security for command and control systems, and requirements analysis for new Microsoft Windows platform applications for various industries. Our consulting services cover a wide range of core competencies, including requirements generation, systems engineering, integration services, communications/network infrastructure operations and maintenance, information assurance, and technology alternative studies.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <hr />
                                    <Table striped borderless>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>Märzen - Seaport Primary Points of Contact</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Mr. Les Graybill</td>
                                                <td><a href='mailto:lgraybill@marzen.com'>lgraybill@marzen.com</a></td>
                                            </tr>
                                            <tr>
                                                <td>Mr. Daniel Abrams</td>
                                                <td><a href='mailto:dga@marzen.com'>dga@marzen.com</a></td>
                                            </tr>
                                            <tr>
                                                <td>Mr. Robert Blaisdell</td>
                                                <td><a href='mailto:rpb@marzen.com'>rpb@marzen.com</a></td>
                                            </tr>
                                            <tr>
                                                <td>Mr. Douglas Morris</td>
                                                <td><a href='mailto:dmorris@marzen.com'>dmorris@marzen.com</a></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}