import React, { useState, useEffect } from 'react'
import { Container, Spinner, Image, Table, Row, Col } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/products/srf-banner.jpg'
import srf from '../../images/products/SRF2.jpg'
import icon from '../../images/services/icons/cpt-icon.png'

export default function SRF() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1><Image src={icon} className='icon' alt='a graphic of a blue monitor with a picture of lines connected on the monitor' />SRF:</h1>
                            <h2>Small Rack Factor</h2>
                        </div>
                        <Image src={header} fluid width='1200' height='400' className='banner' alt='Blue fades in on the sides with a SRF in the middle' />
                        <Container>
                            <Row className='paddingTop'>
                                <Col sm={12} md={6}>
                                    <p>
                                        The BTIN is a Small Rack Form (SRF) factor designed to provide a full complement of TDACS functions in a minimal rack unit (RU) height (3) and minimal depth (16”) The AMK801-17W is a 1U rackmount Keyboard, Video, Mouse (KVM) device. It is designed for single DVI-D video input and can use either a USB or PS/2 connector for the keyboard and mouse input. The TDACs SRF workstations are deployed operationally around the world in the harshest environments.
                                    </p>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className='greyBackground'>
                                        <Image src={srf} width='600px' height='400px' fluid className='center' alt='the front view of a SRF' />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Tech Specs:</th>
                                                <th>Form Factor</th>
                                                <th>Monitor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Overall Length</td>
                                                <td>20.5"</td>
                                                <td>18.9"</td>
                                            </tr>
                                            <tr>
                                                <td>Overall Width</td>
                                                <td>19"</td>
                                                <td>17.4"</td>
                                            </tr>
                                            <tr>
                                                <td>Overall Height</td>
                                                <td>3.5" (2U)</td>
                                                <td>1.7" (1U)</td>
                                            </tr>
                                            <tr>
                                                <td>Weight (lbs.)</td>
                                                <td>44.5 lbs</td>
                                                <td>26 lbs</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs={12}>
                                    <Table striped bordered responsive='sm'>
                                        <thead>
                                            <tr>
                                                <th>Characteristic</th>
                                                <th colSpan={2}>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Power Requirements</td>
                                                <td>1 Three-Pronged 120 VAC outlet (SRF)</td>
                                                <td>1 Three-Pronged 120 VAC outlet (Monitor/KVM)</td>
                                            </tr>
                                            <tr>
                                                <td>Power Consumption</td>
                                                <td>Idle: N/A</td>
                                                <td>Max: N/A</td>
                                            </tr>
                                            <tr>
                                                <td>Temperature</td>
                                                <td>Operating: 32º - 104ºF</td>
                                                <td>Operating: 0º - 40ºC</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}