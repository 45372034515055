import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, Button, Image, Spinner } from 'react-bootstrap'

import header from '../../images/banners/services/services-banner.jpg'
import services from '../../images/services/services.JPG'
import { useSpring, animated, config } from 'react-spring'

import est from '../../images/services/icons/est-icon.png'
import pdt from '../../images/services/icons/pdt-icon.png'
import c2bmc from '../../images/services/icons/c2bmc-icon.png'
import pss from '../../images/services/icons/pss-icon.png'
import west from '../../images/services/icons/west-icon.png'
import sst from '../../images/services/icons/sst-icon.png'
import jtids from '../../images/services/icons/jtids-icon.png'

export default function Services() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1>Märzen Services Overview</h1>
                        </div>
                        <Image src={header} width='1200' height='400' fluid className='banner' alt='Closeup of a piece of paper with TDACS written on it' />

                        <Container>
                            <Row className='paddingTop'>
                                <Col xs={12}>
                                    <h2>Core Capabilities - Services Provided</h2>
                                    <p>
                                    Märzen provides System Engineering and Integration (SE&I) Consulting Services to both U.S. Government and Commercial Customers. Märzen works with the U.S. Department of Defense (DoD), The Missile Defense Agency (MDA), The U.S. Army, the Navy, the Air Force, and several Fortune 500 commercial and non-profit customers. Currently, the efforts of most of the SE&I work is distributed among six teams. Check out the links below for more details:
                                    </p>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <h3>Data Link Support Subject Matter Experts (SMEs)</h3>
                                    <ul>
                                        <li>Military Data Link Standards Implementation and Analysis.</li>
                                        <li>Communications Architecture Design & Deployment.</li>
                                        <li>Test, Planning & Mission Engineering Support.</li>
                                        <li>International Exercise Participation Support.</li>
                                        <li>Joint Interoperability Interface Officers (JICO) Support.</li>
                                        <li>Analysis Support for Communications and Operations.</li>
                                        <li>Independent Validation & Verification (IV&V) Testing.</li>
                                        <li>Security, Cyber and Information Assurance Providers.</li>
                                        <li>TDACS Help Desk and TDACS Maintenance Support</li>
                                        <li>TDACS Operations and Maintenance Training</li>
                                    </ul>
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Image className='blueBackground' width='600' height='400'  src={services} fluid alt='TDACS software running on a PFF' />
                                </Col>
                            </Row>

                            <Row xs={1} md={3} className='g-4'>
                                <Card bg='light' className='productsCard'>
                                    <Card.Body>
                                        <Card.Title><Image src={est} className='icon' alt='a graphic of a laptop with an airplane on the screen' />Engineering Services Team</Card.Title>
                                        <Card.Text>
                                            The Engineering Services Team (EST) provides Exercise and Live Fire Support to the Missile Defense Agency (MDA) and Combatant Command (COCOM) organizations.
                                        </Card.Text>
                                        <p><a href='/est'><Button variant='warning'>Learn More</Button></a></p>
                                    </Card.Body>
                                </Card>

                                <Card className='productsCard'>
                                    <Card.Body>
                                        <Card.Title><Image src={pss} className='icon' alt='a graphic of a cardboard box with a gear on the front panel' />Production Support Services</Card.Title>
                                        <Card.Text>
                                            The PSS is responsible for selecting, testing, and integrating TDACS hardware components and windows software drivers with TDACS software.
                                        </Card.Text>
                                        <p><a href='/pss'><Button variant='warning' type='submit'>Learn More</Button></a></p>
                                    </Card.Body>
                                </Card>

                                <Card bg='light' className='productsCard'>
                                    <Card.Body>
                                        <Card.Title><Image src={pdt} className='icon' alt='a graphic of a laptop with </> on the screen' />Product Development Team</Card.Title>
                                        <Card.Text>
                                            The Product Development Team is responsible for designing, developing, and implementation of all Märzen Group products.
                                        </Card.Text>
                                        <p><a href='/pdt'><Button variant='warning' type='submit'>Learn More</Button></a></p>
                                    </Card.Body>
                                </Card>

                                <Card className='productsCard'>
                                    <Card.Body>
                                        <Card.Title><Image src={c2bmc} className='icon' alt='a graphic of a globe with a satelite' />C2BMC Support Team</Card.Title>
                                        <Card.Text>
                                            The C2BMC Support Team (CST) provides systems engineering and consulting support to the Command & Control Ballistic Missile Contract (C2BMC) managed by the Missile Defense Agency (MDA).
                                        </Card.Text>
                                        <p><a href='/cst'><Button variant='warning' type='submit'>Learn More</Button></a></p>
                                    </Card.Body>
                                </Card>
                                <Card bg='light' className='productsCard'>
                                    <Card.Body>
                                        <Card.Title><Image src={sst} className='icon' alt='a graphic of a magnifying glass with computers inside' />Support Services Team</Card.Title>
                                        <Card.Text>
                                            The Support Services Team (SST) is responsible for software and hardware testing, customer and fielded equipment support, information assurance (IA) compliance, laboratory configuration & management, and product documentation.
                                        </Card.Text>
                                        <p><a href='/sst'><Button variant='warning' type='submit'>Learn More</Button></a></p>
                                    </Card.Body>
                                </Card>

                                <Card className='productsCard'>
                                    <Card.Body>
                                        <Card.Title><Image src={jtids} className='icon' />JTIDS/Link 16/TADIL Engineering Support Team</Card.Title>
                                        <Card.Text>
                                            Currently, Märzen's consulting efforts are focused on the latest generation of tactical communications and networking equipment and provide software engineering and systems management expertise to the US and International communities.
                                        </Card.Text>
                                        <p><a href='/jtids'><Button variant='warning' type='submit'>Learn More</Button></a></p>
                                    </Card.Body>
                                </Card>
                                <Card bg='light' className='productsCard'></Card>

                                <Card className='productsCard'>
                                    <Card.Body>
                                        <Card.Title><Image src={west} className='icon' alt='a graphic of a headset with a gear' />Windows Enterprise Support Team</Card.Title>
                                        <Card.Text>
                                            On Windows platforms, Märzen provides System Engineering and Consulting support to both Military and Commercial customers with a special focus on Hardware Specifications, Software Development, and System Security support.
                                        </Card.Text>
                                        <p><a href='/west'><Button variant='warning' type='submit'>Learn More</Button></a></p>
                                    </Card.Body>
                                </Card>
                                <Card bg='light' className='productsCard'></Card>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )
            }
        </>
    )
}