import React, { useState, useEffect } from 'react'
import { Container, Spinner, Image, Table, Row, Col } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/products/pff-banner.jpg'
import pff from '../../images/products/PFF.jpg'
import icon from '../../images/services/icons/cpt-icon.png'

export default function PFF() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1><Image src={icon} className='icon' alt='a graphic of a blue monitor with a picture of lines connected on the monitor' />PFF:</h1>
                            <h2>Portable Form Factor</h2>
                        </div>
                        <Image src={header} fluid width='1200' height='400' className='banner' alt='Blue fades in on the sides with a PFF in the middle' />
                        <Container>
                            <Row className='paddingTop'>
                                <Col sm={12} md={6}>
                                    <p>
                                        The Portable Form Factor (PFF) is the most recent TDACS form factor to be offered by Märzen. Designed for those users who require the full functionality of a TDACs connection type interfaces but also have portability and small footprint requirements that can be accommodated by a single person. PFF has been used in numerous shipboard events and exercises wherein a single technician can set up and tear down the workstation and transport the hardware off ship with minimal effort or difficulty. The workstation is designed to be able to be easily transported from ship to shore.
                                    </p>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className='greyBackground'>
                                        <Image src={pff} width='600px' height='400px' fluid className='center' alt='the front view of a PFF' />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Tech Specs:</th>
                                                <th>Form Factor</th>
                                                <th>Monitor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Overall Length</td>
                                                <td>15.5"</td>
                                                <td>X</td>
                                            </tr>
                                            <tr>
                                                <td>Overall Width</td>
                                                <td>8.4"</td>
                                                <td>X</td>
                                            </tr>
                                            <tr>
                                                <td>Overall Height</td>
                                                <td>10.4"</td>
                                                <td>X</td>
                                            </tr>
                                            <tr>
                                                <td>Weight (lbs.)</td>
                                                <td>24.5 lbs</td>
                                                <td>X</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs={12}>
                                    <Table striped bordered responsive='sm'>
                                        <thead>
                                            <tr>
                                                <th>Characteristic</th>
                                                <th colSpan={2}>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Power Requirements</td>
                                                <td colSpan={2}>1 Three-Pronged 120 VAC outlet (PFF)</td>
                                            </tr>
                                            <tr>
                                                <td>Power Consumption</td>
                                                <td>Idle: 66.3 Watts, 226.1 BTU/hr</td>
                                                <td>Max: 286.3 Watts, 976.5 BTU/hr</td>
                                            </tr>
                                            <tr>
                                                <td>Temperature</td>
                                                <td>Operating: 32º - 104ºF</td>
                                                <td>Operating: 0º - 40ºC</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}