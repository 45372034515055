import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner, Image } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/services/sst-banner.jpg'
import stock from '../../images/services/sst.JPG'
import sst from '../../images/services/icons/sst-icon.png'

export default function SST() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1><Image src={sst} className='icon' alt='a graphic of a magnifying glass with computers inside' />Support Services Team</h1>
                            <h2>Testing hardware and software</h2>
                        </div>
                        <Image src={header} width='1200' height='400' fluid className='banner' alt='A closeup of circuit board' />
                        <Container>
                            <Row className='paddingTop'>
                                <Col>
                                    <p>
                                        The Support Services Team (SST) is responsible for software and hardware testing, customer and fielded equipment support, information assurance (IA) compliance, laboratory configuration & management, and product documentation. The SST also matrixes staff to the Engineering Services Team (EST) in support of Missile Defense Agency (MDA) manpower surge periods when requested. SST supports the Product Development Team (PDT) in the Tactical Data Analysis and Connectivity System (TDACS) production process by assisting in TDACS software installation, IA scanning/patching, and Quality Assurance (QA) review of pre-delivery production units.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={4} lg={4}>
                                    <Image className='greyBackground' width='600' height='400' src={stock} fluid alt='Engineer testing TDACS software' />
                                    <p className='caption'>Engineer testing TDACS software on our latest hardware</p>
                                </Col>
                                <Col xs={12} md={8} lg={8}>
                                    <p>
                                        The SST conducts TDACS version and sub-version testing in the Headquarters unclassified and classified test labs, as well as in the Virginia Beach site. SST members provide inputs on testing results to the TDACS Bug Tracker, for TDACS Configuration Control Board (CCB) review.
                                    </p>
                                    <p>
                                        SST also provides representation to the CCB. SST is also responsible for the configuration and maintenance of both the classified and unclassified laboratories at the Headquarters site, as well as management and ATO compliance for the Märzen Group Classified Automated Information System (AIS). SST is responsible for the maintenance of the TDACS Operators Manual, Maintenance Manual, Formal Training documentation, and associated briefs and publications.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        The SST provides support to existing customers in the management of the Help Desk and IA Preparation subscription products, as well as providing equipment Install and Checkout, and Formal Training services. SST manages the TDACS Help Desk subscription. Customer Help requests are serialized and tracked. Distance support is provided for TDACS issues. SST also supports Help Desk site visits. SST is responsible for developing and testing TDACS OSA STIG compliance settings, as well as the monthly scanning, patching, IA monthly product assembly and documentation, testing, and deployment. Colorado Springs area/MDA customers are primarily supported by SST with matrix support from EST and potentially other elements. SST also assists PDT field testing efforts when requested.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}