import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Table, Spinner } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

export default function AdminInfo() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1>Administration Contract Information</h1>
                        </div>
                        <Container>
                            <Row className='paddingTop'>
                                <Col>
                                    <Table striped >
                                        <tbody>
                                            <tr>
                                                <td>CAGE CODE</td>
                                                <td>1N5V9</td>
                                            </tr>
                                            <tr>
                                                <td>DUNS NUMBER</td>
                                                <td>124654893</td>
                                            </tr>
                                            <tr>
                                                <td>TAX ID NUMBER</td>
                                                <td>02-0495316</td>
                                            </tr>
                                            <tr>
                                                <td>GSA NUMBER</td>
                                                <td>GS-35F-0053L</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <Table>
                                        <tbody>
                                            <tr>
                                                <td>Primary Billing POC:</td>
                                                <td>
                                                    Robert P. Blaisdell
                                                    <br />
                                                    Märzen Group LLC
                                                    <br />
                                                    24 Railroad Square
                                                    <br />
                                                    Nashua, NH 03064-2278
                                                    <br />
                                                    Tel: (603) 889-9522
                                                    <br />
                                                    Fax: (603) 889-9567
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Alternate Billing POC:</td>
                                                <td>
                                                    Douglas Morris
                                                    <br />
                                                    Märzen Group LLC
                                                    <br />
                                                    24 Railroad Square
                                                    <br />
                                                    Nashua, NH 03064-2278
                                                    <br />
                                                    Tel: (603) 889-9522
                                                    <br />
                                                    Fax: (603) 889-9567
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table striped>
                                        <tbody>
                                            <tr>
                                                <td>Are you a Small Business under SIC Code 7379 (Computer Related Services, N.E.C.) (FAR PART 19.102)?</td>
                                                <td>  YES __X__  NO _____</td>
                                            </tr>
                                            <tr>
                                                <td>Are you a Small Business Administration (SBA) certified Small Disadvantaged Business (SDB)?</td>
                                                <td>  YES _____  NO __X__</td>
                                            </tr>
                                            <tr>
                                                <td>Are you a Woman-Owned Business?</td>
                                                <td>  YES _____  NO __X__</td>
                                            </tr>
                                            <tr>
                                                <td>Are you a Disable Veteran-Owned Business?</td>
                                                <td>  YES _____  NO __X__</td>
                                            </tr>
                                            <tr>
                                                <td>Are you a Hub-Zone Business?</td>
                                                <td>  YES _____  NO __X__</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Table >
                                        <tbody>
                                            <tr>
                                                <td>Cognizant GSA Office: </td>
                                                <td>
                                                    General Services Administration
                                                    <br />
                                                    FSS Contract Management Division
                                                    <br />
                                                    ACO: Rena Willis
                                                    <br />
                                                    Thomas P. O'Neill Federal Building
                                                    <br />
                                                    10 Causeway Street, Room 347
                                                    <br />
                                                    Boston, MA 02222
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Cognizant DFAS Office: </td>
                                                <td>
                                                    Defense Finance & Accounting System (DFAS) Columbus Center
                                                    <br />
                                                    DFAS-CO/Bunker-Hill
                                                    <br />
                                                    P.O. Box 182077
                                                    <br />
                                                    Columbus, OH 43218-2077
                                                    <br />
                                                    (614) 693-6700
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}