import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image, Card, Spinner, Button } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/products/form-factor-banner.jpg'
import logo from '../../images/logos/TDACS-logo.jpg'
import loadingScreen from '../../images/products/software/TDACS.gif'
import cpt from '../../images/services/icons/cpt-icon.png'
import sftwr from '../../images/services/icons/sftwr.png'
import PFF from '../../images/products/PFF.jpg'
import CRF from '../../images/products/CRF.jpg'
import SRF from '../../images/products/SRF.jpg'
import SFF from '../../images/products/SFF.jpg'
import IFF from '../../images/products/IFF.png'

export default function Products() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1>TDACS:</h1>
                            <h2>Tactical Data Analysis and Connectivity System</h2>
                        </div>
                        <Image src={header} width='1200' height='400' fluid className='banner' alt='Wide angle of of six TDACS computers' />
                        <Container>
                            <Row className='paddingTop'>
                                <Col xs={12} md={8}>
                                    <h2>What is TDACS?</h2>
                                    <p>TDACS is a suite of hardware and software tools constructed to support connectivity, interoperability, and real time analysis of the tactical data networks used by many commercial, US government and NATO agencies. The TDACS system is built around two primary components, a robust and low latency message processing engine, and a powerful and scalable Relational Database Management System (RDBMS). The TDACS message engine is designed to be flexible and extensible through using a tightly controlled common interface definition. Through this common interface definition, the TDACS message processor gains access to multiple software module plug-ins that expand and extend the overall system capabilities.  The common interface plug-ins are automatically installed at system startup, the capabilities are immediately available to the message engine for use, and the user interface is automatically updated to allow management of the new capabilities. The TDACS software is commercial off-the-shelf (COTS) and is optimized to make use of COTS hardware that is certified for TDACS operations. TDACS software is built to operate on the Microsoft Windows 10, 64-bit, operating system. Märzen certification for the use of TDACS on Windows 11 is targeted for Q1-2023.</p>
                                    <h3><small>TDACS is a powerful and versatile data link management and analysis tool that has many applications:</small></h3>
                                    <ul>
                                        <li>High Performance, Low Latency Message Router.</li>
                                        <li>Routes Data to/from Multiple Connections.</li>
                                        <li>Translates Numerous J-Series (Link 16) Protocols.</li>
                                        <li>Facilitates Multiple Network Connections & Interfaces.</li>
                                        <li>SQL Data Capture for Real-Time & Post-Event Analysis.</li>
                                        <li>Monitors Tactical, Strategic, and Laboratory Environments.</li>
                                        <li>Filters & Manipulates Data.</li>
                                        <li>A "No Data Manipulation" Version is available.</li>
                                        <li>Versatile Record & Playback Capability.</li>
                                        <li>Easy–To-Use COTS Software (Microsoft Excel, PowerPoint, Visio).</li>
                                        <li>2D/3D Real-Time Graphics Display for Situational Awareness (SA)</li>
                                    </ul>
                                </Col>
                                <Col xs={12} md={4} className='center'>
                                    <Image className='TDACSLogo' width='500px' height='500px' fluid src={logo} alt='TDACS Logo' />
                                    <p className='caption'>Whether in a lab environment or deployed in the field, TDACS enhances and simplifies Tactical Network Management.</p>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={12}>
                                    <h2>What does TDACS do?</h2>
                                    <p>
                                    The TDACS tool suite provides a robust and stable tactical message processing engine for routing, protocol conversion, data filtering & manipulation, and data analysis. Incorporating Commercial off-the-shelf (COTS) software and hand-selected hardware, the TDACS link management tool helps to simplify the job of tactical network management.
                                    </p>
                                    <p>
                                        TDACS workstations are used daily by customers to support live operations, testing, training, IV&V, host platform development, and TDL data archiving. It also supports detailed real-time and post-operation playback and mission analysis. TDACS is often employed in a network with other TDL participants, both actively and passively, primarily as forwarding and translating tool, providing TDL interoperability support to both multi-link legacy and emerging capabilities.
                                    </p>
                                    <p>
                                        Together with the TDACS hardware, the TDACS software forms a multipurpose, multi-link, TDL workstation and a simulator that provides a modern, familiar, and flexible system capable of supporting operational, training, and testing environments.
                                    </p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <h2 id='h2Products'>TDACS Commercial off-the-shelf Software</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Image fluid src={loadingScreen} width='800px' height='500px' className='greyBackground' alt='TDACS loading screen' />
                                    <p className='caption'>Pictured is the loading screen that auto-populates whilst loading TDACS</p>
                                </Col>
                                <Col lg={6}>
                                    <Card bg='light' className='marginBottom'>
                                        <Card.Body >
                                            <Card.Title bsPrefix='card-title'><Image src={sftwr} alt='a graphic of a the earth with three computers inside' className='icon' />TDACS Software</Card.Title>
                                            <Card.Text>
                                                <p>
                                                    TDACS is an operator-controlled workstation that provides multiple data link capabilities necessary to effectively operate, manage, and analyze a Tactical Data Link (TDL) network. The application software is directly interfaced with Microsoft Office 2016 products. The Office applications Excel, PowerPoint, and Visio are used to provide visual tools for real-time data analysis, data presentation, and network management of the data gathered on the datalink connections. All TDL message data on each connection can be recorded in raw format and/or time-tagged.
                                                </p>
                                            </Card.Text>
                                            <div className='center'>
                                                <p><a href='/software'><Button variant='warning' type='submit'>Learn More</Button></a></p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <h2 id='h2Products'>TDACS Custom Hardware</h2>
                                </Col>
                            </Row>
                            <Row className='justify-content-md-center'>
                                <Card bg='light' className='productsCard'>
                                    <Card.Img variant='top' width='250px' height='250px' src={CRF} alt='TDACS CRF' />
                                    <Card.Body >
                                        <Card.Title bsPrefix='card-title'><Image src={cpt} className='icon' alt='a graphic of a blue monitor with a picture of lines connected on the monitor'/>Combined Rack Factor (CRF)</Card.Title>
                                        <Card.Text>
                                            The CRF is the new TDACS Combined Rack Form Factor (CRF) workstation is for those users without space limitations that usually work in either exercise suites or laboratory environments.
                                        </Card.Text>
                                        <p><a href='/crf'><Button variant='warning' type='submit'>Learn More</Button></a></p>
                                    </Card.Body>
                                </Card>
                                <Card className='productsCard'>
                                    <Card.Img variant='top' width='250px' height='250px' src={SRF} alt='TDACS SRF' />
                                    <Card.Body>
                                        <Card.Title bsPrefix='card-title'><Image src={cpt} className='icon'  alt='a graphic of a blue monitor with a picture of lines connected on the monitor'/>Small Rack (SRF)</Card.Title>
                                        <Card.Text>
                                            The SRF was designed to provide a full complement of TDACS functions in a minimal rack unit (RU) height (3) and minimal depth (16").
                                        </Card.Text>
                                        <p><a href='/srf'><Button variant='warning' type='submit'>Learn More</Button></a></p>
                                    </Card.Body>
                                </Card>
                                <Card bg='light' className='productsCard'>
                                    <Card.Img variant='top' width='250px' height='250px' src={PFF} alt='TDACS PFF' />
                                    <Card.Body>
                                        <Card.Title bsPrefix='card-title'><Image src={cpt} className='icon'  alt='a graphic of a blue monitor with a picture of lines connected on the monitor'/>Portable Form Factor (PFF)</Card.Title>
                                        <Card.Text>
                                            The PFF is built with those in mind who require the full functionality of a TDACS connection but also need transportability.
                                        </Card.Text>
                                        <p><a href='/pff'><Button variant='warning' type='submit'>Learn More</Button></a></p>
                                    </Card.Body>
                                </Card>

                            </Row>


                            <Row className="justify-content-md-center">
                                <Card className='productsCard'>
                                    <Card.Img variant='top' width='250px' height='250px' src={SFF} alt='TDACS SFF' />
                                    <Card.Body>
                                        <Card.Title bsPrefix='card-title'><Image src={cpt} className='icon' alt='a graphic of a blue monitor with a picture of lines connected on the monitor'/>Small Form Factor (SFF)</Card.Title>
                                        <Card.Text>
                                            The SFF was designed to handle those user requirements that put a premium on space utilization and limitations on functionality and display.
                                        </Card.Text>
                                        <p><a href='/sff'><Button variant='warning' type='submit'>Learn More</Button></a></p>
                                    </Card.Body>
                                </Card>
                                <Card bg='light' className='productsCard'></Card>
                                <Card className='productsCard'>
                                    <Card.Img variant='top' width='250px' height='250px' src={IFF} alt='TDACS IFF' />
                                    <Card.Body>
                                        <Card.Title bsPrefix='card-title'><Image src={cpt} className='icon' alt='a graphic of a blue monitor with a picture of lines connected on the monitor' />Intermediate Form Factor (IFF)</Card.Title>
                                        <Card.Text>
                                            The IFF is for those users who require the full functionality of a dual display TDACS while also have portability
                                            and small footprint requirements.
                                        </Card.Text>
                                        <p><a href='/iff'><Button variant='warning' type='submit'>Learn More</Button></a></p>
                                    </Card.Body>
                                </Card>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}