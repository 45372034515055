import config from "../../config";
import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useSpring, animated } from "react-spring";

export default function RegistrationReport() {

    const [name, setName] = useState('')
    const [company, setCompany] = useState('')
    const [email, setEmail] = useState('')
    const [tdacs, setTdacs] = useState('')
    const [product, setProduct] = useState('')
    const [regCode, setRegCode] = useState('')
    const [prodSku, setProdSku] = useState('')
    const [mac, setMac] = useState('')
    const [moboSerial, setMoboSerial] = useState('')
    const reportType = 'TDACS Registration Request'

    const onNameChange = e => setName(e.target.value)
    const onCompanyChange = e => setCompany(e.target.value)
    const onEmailChange = e => setEmail(e.target.value)
    const onTdacsChange = e => setTdacs(e.target.value)
    const onProductChange = e => setProduct(e.target.value)
    const onRegCodeChange = e => setRegCode(e.target.value)
    const onProdSkuChange = e => setProdSku(e.target.value)
    const onMacChange = e => setMac(e.target.value)
    const onMoboSerialChange = e => setMoboSerial(e.target.value)

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(flip),
    })
    
    const handleClose = () => document.location.reload(true)

    function sendEmail(e) {
        e.preventDefault()
        const data = { name, company, email, tdacs, product, regCode, prodSku, mac, moboSerial, reportType }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
        fetch(`${config.API_ENDPOINT}`, requestOptions)
            .then(res =>
                (!res.ok)
                    ? res.then(e => Promise.reject(e))
                    : console.log(res))
            .then(handleClose)
    }

    const [toggle, setToggle] = useState(null)
    const [machineToggle, setMachineToggle] = useState(null)

    return (
        <>
            <animated.div style={fade}>

                <div className='helpDeskContact'>
                    <Form onSubmit={sendEmail} name="reportType" value='TDACS Registration Code Request'>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label >Full Name</Form.Label>
                            <Form.Control
                                required
                                placeholder='John Doe'
                                onChange={onNameChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='company'>
                            <Form.Label >Company / Command / Unit Name</Form.Label>
                            <Form.Control
                                required
                                placeholder='Märzen'
                                onChange={onCompanyChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='email'>
                            <Form.Label >Email</Form.Label>
                            <Form.Control
                                required
                                type='email'
                                placeholder='John@marzen.com'
                                onChange={onEmailChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='tdacs'>
                            <Form.Label>TDACS Number (Last 4)</Form.Label>
                            <Form.Control
                                required
                                placeholder='0295'
                                onChange={onTdacsChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='product'>
                            <Form.Label>Product Name</Form.Label>
                            <Form.Control
                                required
                                placeholder='TDACS Standard Edition'
                                onChange={onProductChange}
                            />
                        </Form.Group>
                        <p>Do you know your registration code?</p>
                        <div className="regQs">
                            <Button onClick={() => setToggle('yes')}>Yes</Button>
                            <Button onClick={() => setToggle('no')}>No</Button>
                        </div>
                        {toggle === 'yes' &&
                            <Form.Group className='mb-3' controlId='regCode'>
                                <Form.Label >Registration Code</Form.Label>
                                <Form.Control
                                    required
                                    placeholder='123456789'
                                    onChange={onRegCodeChange}
                                />
                            </Form.Group>
                        }
                        {toggle === 'no' &&
                            <>
                                <p>Is this a virtual or physical machine?</p>
                                <div className="regQs">
                                    <Button onClick={() => setMachineToggle('virtual')}>Virtual Machine</Button>
                                    <Button onClick={() => setMachineToggle('physical')}>Physical Machine</Button>
                                </div>
                                {machineToggle === 'virtual' &&
                                    <>
                                        <Form.Group className='mb-3' controlId='prodSku'>
                                            <Form.Label>Product SKU #</Form.Label>
                                            <Form.Control
                                                required
                                                type='number'
                                                placeholder='26101'
                                                onChange={onProdSkuChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className='mb-3' controlId='mac'>
                                            <Form.Label>MAC Address</Form.Label>
                                            <Form.Control
                                                required
                                                placeholder='123456789'
                                                onChange={onMacChange}
                                            />
                                        </Form.Group>
                                    </>
                                }
                                {machineToggle === 'physical' &&
                                    <Form.Group className='mb-3' controlId='moboSerial'>
                                        <Form.Label>Motherboard Serial Number</Form.Label>
                                        <Form.Control
                                            required
                                            placeholder='123456789'
                                            onChange={onMoboSerialChange}
                                        />
                                    </Form.Group>
                                }
                            </>
                        }
                        <div className='ctrBtn'>
                            <Button variant="warning" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>

            </animated.div>
        </>
    )
}