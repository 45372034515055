import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner, Image } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/services/pss-banner.jpg'
import pss from '../../images/services/icons/pss-icon.png'
import cable from '../../images/products/ntwk-cable.jpg'

export default function PSS() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1><Image src={pss} className='icon' alt='a graphic of a cardboard box with a gear on the front panel' />Production Support Services</h1>
                            <h2>The heart of anything hardware related</h2>
                        </div>
                        <Image src={header} width='1200' height='400' fluid className='banner' alt='Various memory cards on top of each other' />

                        <Container>
                            <Row className='paddingTop'>
                                <Col xs={12} md={6} xl={7}>
                                    <p>
                                    Production Support Services (PSS) is responsible for the production of TDACS hardware. PSS researches, tests, and integrates all TDACS hardware components to keep near the leading edge of COTS hardware development. PSS manages five (5) workstation hardware form factors that operate on the Windows 10, 64-bit, operating system. PSS continuously verifies interoperability with the specialty hardware necessary for interconnection with tactical military communications equipment.
                                    </p>
                                    <p>
                                        The five form factors PSS are in charge of are:
                                    </p>
                                    <ul>
                                        <li>CRF: Combined Rack Factor</li>
                                        <li>SRF: Small Rack Factor</li>
                                        <li>PFF: Portable Form Factor</li>
                                        <li>IFF: Intermediate Form Factor</li>
                                        <li>SFF: Small Form Factor</li>
                                    </ul>
                                    <p>
                                        To learn more about the TDACS form factors please visit the <a href='/products'>TDACS overview</a>.
                                    </p>
                                </Col>

                                <Col xs={12} md={6} xl={5}>
                                    <Image width='600' height='400' src={cable} alt='Yellow ethernet cables' fluid />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}