import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/services/pdt-banner.jpg'
import pdt from '../../images/services/icons/pdt-icon.png'
import twoD from '../../images/products/software/2D.gif'

export default function PDT() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1><Image src={pdt} className='icon' alt='a graphic of a desktop with </> on the screen' />Product Development Team</h1>
                            <h2>Developing and testing TDACS software</h2>
                        </div>
                        <Image className='banner' width='1200' height='400'  src={header} fluid alt='code overlayed on top of a graphic of earth' />

                        <Container>
                            <Row className='paddingTop'>
                                <Col xs={12} md={12} xl={7}>
                                    <p>
                                        The Product Development Team is responsible for designing, developing, and implementation of all Märzen Group software. The primary software product managed by the PDT is the Tactical Data Analysis and Connectivity System (TDACS) software suite. This software suite permits interconnection with multiple military tactical communications networks and enables near-real-time data monitoring, analysis, conversion, and intra-network routing. Other PDT products provided under the TDACS include:
                                    </p>
                                    <ul>
                                        <li>Static Tactical Message Injection [TMI]</li>
                                        <li>Dynamic TADIL J Scenario Generation</li>
                                        <li>DIS/ TADIL J Adjudication</li>
                                        <li>TDACS 2D and 3D Map and GIS products</li>
                                        <li>TDACS to Microsoft Office Monitoring and Analysis Tools</li>
                                    </ul>
                                </Col>
                                <Col xs={12} md={6} xl={5}>
                                    <Image className='greyBackground' width='600' height='400' src={twoD} alt='TDACS 2D Map' fluid />
                                    <p className='caption'>TDACS 2D Map</p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}