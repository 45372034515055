import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner, Image } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/services/jtids-banner.jpg'
import jtids from '../../images/services/icons/jtids-icon.png'
import jet from '../../images/services/hornet.jfif'
import cables from '../../images/services/back-panel.JPG'

export default function JTIDS() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1><Image src={jtids} className='icon' alt='a graphic of a Link 16 network and connections' />JTIDS/Link 16/TADIL Engineering Support Team</h1>
                            <h2>Developing and testing Link 16 operations</h2>
                        </div>
                        <Image src={header} width='1200' height='400' fluid className='banner' alt='blue and purple connections over a map on a black background' />
                        <Container>
                            <Row className='paddingTop'>
                                <Col xs={12} lg={6}>
                                    <p>
                                        Märzen offers a wide range of expertise and practical field experience in the development of specialized software systems and components in the support of real-time tactical communication systems. Märzen staff have years of “hands-on” experience with these real-world tactical data architectures in both exercise and operational environments and have developed a firm understanding of their capabilities and limitations.
                                    </p>
                                    <p>
                                        Drawing from this experience Märzen software engineers have come to excel in the rapid design, development, and deployment of object-oriented software systems to augment existing tactical systems. Märzen staff has often been tasked to deploy on very short notice to provide capabilities in remote locations that are essential to the success of a mission. Consequently, Märzen staff often play a significant role in both military exercises and theatre operations by devising improvements to tactical operations with software and hardware solutions.
                                    </p>
                                </Col>
                                <Col xs={12} md={6} lg={6}>
                                    <Image className='greyBackground' width='600' height='400'  src={jet} alt='A Marine Fighter Attack Squadron 112 F/A-18 Hornet takes off during exercise Noble Jaguar 2021' fluid />
                                    <p className='caption'>"The appearance of U.S. Department of Defense (DoD) visual information does not imply or constitute DoD endorsement." Photo courtesy of US Navy, Sgt. Booker Thomas, "211001-M-TE205-027", 2021. No Copyright, See Commons: <a href='https://creativecommons.org/licenses/by-nd/1.0/legalcode'>Licensing</a>, <a href='https://www.navy.mil/Resources/Photo-Gallery/igphoto/2002866156/'>Source</a></p>
                                </Col>
                                <Col xs={12} md={6} lg={6}>
                                    <Image className='greyBackground' width='600' height='400'  src={cables} alt='The back of a computer with multiple cables plugged in' fluid />
                                    <p className='caption'>The back connector panel of a CRF</p>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <p>
                                        Märzen staff has extensive knowledge and experience with DOD tactical communications systems and the various TADIL message standards (Link 1, 4, 11, 11b, 16, 22, etc.). Märzen staff participate and sometimes lead the efforts to design and integrate the JTIDS radio and the Link 16 tactical message standard into several DOD aircraft and weapons systems including the F-15, AWACS, Joint Stars, GTACS, and ABCCC.
                                    </p>
                                    <p>
                                        Märzen's expertise includes such diverse subjects as; JTIDS/MIDS radios, multi-TADIL network design, secure digital encryption devices, ethernet networks, tactical data modems, data forwarding, and specialized serial devices.
                                    </p>
                                    <p>
                                        Märzen staff has consulted on the design and fielding of a number of the test tools and systems currently in service that are required for platform integration and Link 16 network testing.
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        Currently, Märzen's consulting efforts are focused on the latest generation of tactical communications and networking equipment and provide software engineering and systems management expertise to the US and International communities.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}