import React, { useState, useEffect } from 'react'
import { Spinner, Container, Image, Col, Row, Button } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import IFF from '../images/products/IFF.png'
import awardNight from '../images/award.jpg'
import cityscape from '../images/banners/cityscape.jpeg'
import cartoon from '../images/banners/cartoon.jpg'
import connections from '../images/banners/connections.jpeg'

export default function Home() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <Row>
                            <Col>
                                <Image
                                    className='fit'
                                    src={cityscape}
                                    width='1200'
                                    height='700'
                                    alt='city buildings from the perspective of the street and looking up at sky scrapers'
                                />
                                <div className='overlay'>
                                    <h2 className='h2Home'>Providing consulting services and products for over twenty years</h2>
                                    <a href='/services'><Button size='lg' className='homeBtn' variant='success'>Services Details</Button></a>
                                    <a href='/products'><Button size='lg' className='homeBtn' variant='success'>Product Information</Button></a>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Image
                                    className='fit'
                                    src={connections}
                                    width='1200'
                                    height='700'
                                    alt='a blue background filled with different lines connecting to each other to represent connectivity'
                                />
                                <div className='overlay'>
                                    <Image className='center' fluid src={IFF} alt='a black rectangular computer' width='400' height='400' />
                                    <h1 className='h1Home'>Tactical Data Analysis and Connectivity System</h1>
                                    <p className='pHome'>TDACS is a second-generation message processing engine that provides enhanced interoperability and real-time analysis.</p>
                                    <a href='/software'><Button size='lg' className='homeBtn' variant='success'>Software Information</Button></a>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Image
                                    className='fit'
                                    src={awardNight}
                                    width='1200'
                                    height='700'
                                    alt='four men on stage with the two in the middle holding an award'
                                />
                                <div className='overlay'>
                                    <h2 className='h2Home'>Home to certifiable award-winning expertise</h2>
                                    <a href='/about'><Button size='lg' className='homeBtn' variant='success'>Learn More</Button></a>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row>
                            <Col>
                                <Image
                                    className='fit'
                                    src={cartoon}
                                    width='1200'
                                    height='700'
                                    alt='a blue background overlayed with dark blue icons representing different types of technology'
                                />
                                <div className='overlay'>
                                    <h2 className='h1Home'>Curious? Reach out to us with your questions</h2>
                                    <a href='/contact'><Button size='lg' className='homeBtn' variant='success'>Contact Us</Button></a>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}