import React, { useState, useEffect } from 'react'
import { Container, Spinner, Accordion, Card, Image } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../images/banners/news.jpeg'

export default function News() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1>Current Events</h1>
                        </div>
                        <Image src={header} fluid width='1200' height='400' className='banner' alt='Blue fades in on the sides with a CRF in the middle' />
                        <Container>

                            <Accordion defaultActiveKey='20'>
                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='20'>
                                            <h2 className='h2News'>November 2022</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='20'>
                                        <Card.Body>
                                        Märzen Group LLC is pleased to welcome Mr. Phillip Norton. Phillip Norton is the newest Systems Engineer on the Engineering Support Team (EST) in Colorado Springs in support of the IRES contract, which supports the Missile Defense Agency in exercise and warfighter events throughout the world. Phillip will utilize Marzen Group’s TDACS to conduct Tactical Data Link analysis for simulation and hardware systems. Phillip recently separated from the U.S. Navy.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='19'>
                                            <h2 className='h2News'>August 2022</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='19'>
                                        <Card.Body>
                                            Märzen Group LLC is pleased to welcome Mr. Kyle Fisher. Kyle Fisher is the newest Software Engineer on the Product Development Team (PDT) in Nashua, NH. Kyle will specialize in TDACS software plugin design, whilst improving functional attributes of several additional components within the TDACS software product line.
                                            <br /><br />
                                            Märzen Group LLC will also support several TDACS service-related contractual activities within Japan. This includes TDACS Installation and Checkout (I&CO), Acceptance Test Capability Demonstrations (ATCD), and TDACS Formal Training activities.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='18'>
                                            <h2 className='h2News'>July 2022</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='18'>
                                        <Card.Body>
                                            Märzen Group LLC is pleased to welcome back Mr. Liam McCall. Liam interns on the Product Development Team (PDT). He continues to assist with various mathematical models, as well as designing and building new functionality within the TDACS 3D map software.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='17'>
                                            <h2 className='h2News'>June 2022</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='17'>
                                        <Card.Body>
                                            Märzen Group LLC is pleased to welcome Mr. Jordan Trombly. Jordan will assist the Product Development Team (PDT) as an intern with an assortment of responsibilities such as data manipulation/management, configuration management of TDACS hardware and software, developing/testing/documenting and distributing code, and assisting with the shipment of TDACS products.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='16'>
                                            <h2 className='h2News'>May 2022</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='16'>
                                        <Card.Body>
                                            Märzen Group LLC is pleased to welcome Mr. Eric Orth. Eric Orth is the newest Systems Engineer on the Engineering Support Team (EST) in Colorado Springs supporting the IRES contract, which supports the Missile Defense Agency in exercise and warfighter events throughout the world. Eric will utilize Marzen Group’s TDACS to conduct Tactical Data Link analysis for simulation and hardware systems. Eric recently retired from the USMC.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='15'>
                                            <h2 className='h2News'>April 2022</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='15'>
                                        <Card.Body>
                                            Märzen Group released the latest version of TDACS V5.6.1.10 Patch 2 on April 28th, 2022. Patch 2 enhanced upon: displaying new message types, creating dynamic file saving​, adding multiple filtering options and much more.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='14'>
                                            <h2 className='h2News'>March 2022</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='14'>
                                        <Card.Body>
                                            Marzen Group Engineering Service Team supports multiple Coalition Exercise Events supporting the Missile Defense Agency. They acted as Data Link Subject Matter Experts and performed functional analyses of exercise players.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='13'>
                                            <h2 className='h2News'>February 2022</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='13'>
                                        <Card.Body>
                                            Märzen Group is pleased to announce the opening of a new branch in Virginia Beach. The Virginia Beach office will be the new home office of our growing sales team and an extension of our TDACS testing center. For more information on how to contact our Virginia office, <a href='/contact'>click here</a>.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='12'>
                                            <h2 className='h2News'>January 2022</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='12'>
                                        <Card.Body>
                                            Märzen Group LLC is pleased to welcome Mr. Tyler S. Allen. Tyler will act as the Government Contracts Manager in the Corporate Office in Nashua, NH. He will be administratively responsible for tracking and executing all required contract actions, leading export compliance for ITAR and EAR regulations, tracking GSA Schedule contract efforts, and assisting in customer relations for the company with our Prime vendors.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='11'>
                                            <h2 className='h2News'>December 2021</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='11'>
                                        <Card.Body>
                                            Märzen Group LLC staff assisted the TDACS user community with the updates of various TDACS software and information assurance (IA) packages to prepare for upcoming coalition exercises and testing events.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='10'>
                                            <h2 className='h2News'>November 2021</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='10'>
                                        <Card.Body>
                                            Märzen Group LLC announced the release of TDACS Version 5.6.1.10 Patch 1 which provided enhancements/upgrades to TDACS Dynamic Scenario Injector Link (DSI-L), TDACS Link 11A NTDS/Link 16 Data Forwarding, TDACS Satellite J Protocol, and TDACS MIL-STD 3011B JREAP-A. Märzen Group LLC staff also continued supporting multiple overseas coalition exercise planning meetings in several countries.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='9'>
                                            <h2 className='h2News'>October 2021</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='9'>
                                        <Card.Body>
                                            Märzen Group LLC staff supported multiple overseas coalition exercise meetings held in Japan, Germany, and Korea. This included supporting planning conferences and data link testing events. Our staff acted as Subject Matter Experts (SMEs) in military data link message processing and routing. They also participated in the design of exercise coalition communications infrastructure. In some cases, Marzen SMEs operated TDACS equipment and monitored exercise assets for message standard compliance and proper operation with various Military Standards.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='8'>
                                            <h2 className='h2News'>September 2021</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='8'>
                                        <Card.Body>
                                            Märzen Group LLC is pleased to welcome Mr. Carl Van Dyke. Carl works as a Senior Systems Engineer on the Engineering Support Team (EST) and performs engineering activities in support of the Missile Defense Agency IRES contract supporting COCOM and War Fighter Exercises effort.
                                            <br /><br />
                                            Märzen Group LLC is pleased to welcome Ms. Tiffany Summerford. Tiffany is a Junior Technician on the TDACS Product Support Services (PSS) team. She works on various activities like tracking and configuration management of various TDACS components, unit burn-in, software loading, TDACS packaging, and TDACS shipping. Tiffany also participates with the IT staff in the updating of administrative computing assets, modernizing various web assets, improving TDACS Marketing publications, and implementing SharePoint products.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='7'>
                                            <h2 className='h2News'>July 2021</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='7'>
                                        <Card.Body>
                                            Märzen Group LLC announces the release and general availability of Version 5.6 of the Tactical Data Analysis and Connectivity System (TDACS) to be 19 July 2021. This version of TDACS software includes several new features including several new tactical radio interfaces, LINK 11A processing, and data forwarding and JREAP A protocol enhancements, and significant enhancements to the Dynamic Scenario Injection- Link16 [DSI-L].
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='6'>
                                            <h2 className='h2News'>June 2021</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='6'>
                                        <Card.Body>
                                            Märzen Group LLC is pleased to welcome the return of Mr. Kyle Fisher, as an Intern. Kyle is once again a member of the Product Development Team (PDT) and assists in the software development of various functional aspects of the TDACS application platform.
                                            <br /><br />
                                            Märzen Group LLC is pleased to welcome Mr. Marcel Jussaume. Marcel works as a Principal Engineer in the Corporate office and performs subject matter expert engineering activities in support of the TDACS development, testing, deployment, and support. Marcel will also assist in the marketing and operational support of our various TDACS communities of interests and customers.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='5'>
                                            <h2 className='h2News'>May 2021</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='5'>
                                        <Card.Body>
                                            Märzen Group LLC is pleased to welcome Ms. Tiffany Summerford. Tiffany, as an Intern, assists the TDACS Product Support Services (PSS) team in various activities like tracking and configuration management of various TDACS components, unit burn-in, software loading, TDACS packaging, and TDACS shipping. She will also work with the IT staff in the updating of various web and SharePoint products.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>


                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='4'>
                                            <h2 className='h2News'>March 2021</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='4'>
                                        <Card.Body>
                                            Märzen Group LLC tactical data link specialists technical staff completed participation in three months of multiple worldwide COCOM Exercises. These events provide training to US active duty and reserve warfighters, as well as coalition partners under field conditions. They also acted as event SMEs that measured interoperability of data link platforms against various US and coalition standards. This required many hours of COVID 19 quarantine activities, as well as extensive long-term overseas travel and exercise support.
                                            <br /><br />
                                            Märzen Group LLC subject matter experts (SMEs) completed participation in Joint Project Optic Windmill 2021 with the Ministry of Defense in the Netherlands. Activities included the planning of the event, development of various communication architectures, risk reduction testing, and actual participation in the operational exercise events. Post-test analysis activities will be completed in the June 2021 timeframe.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='3'>
                                            <h2 className='h2News'>October 2020</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='3'>
                                        <Card.Body>
                                            Märzen Group LLC is pleased to welcome Mr. Liam McCall, as an Intern. Liam is a member of the Product Development Team (PDT). He assists in the development of various functional aspects of the TDACS platform and works with various mathematical models to be included within the TDACS product.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='2'>
                                            <h2 className='h2News'>February 2020</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='2'>
                                        <Card.Body>
                                            Märzen Group LLC pleased to welcome is pleased to welcome Mr. Will Jacks. Will is working as a Systems Engineer in the Engineering Support Team (EST) and performs engineering activities in support of the Missile Defense Agency IRES contract supporting COCOM and War Fighter Exercises effort.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='1'>
                                            <h2 className='h2News'>January 2020</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='1'>
                                        <Card.Body>
                                            Märzen Group LLC is pleased to welcome Mr. Cody Williams. Cody is a member of the Product Development Team (PDT). He assists in the development and improvement of TDACS scenario generation capabilities and the implementation of various Datalink and simulation protocols.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>

                                <Card>
                                    <Card.Header>
                                        <Accordion.Toggle as={Card.Header} eventKey='0'>
                                            <h2 className='h2News'>June 2019</h2>
                                        </Accordion.Toggle>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey='0'>
                                        <Card.Body>
                                            Märzen Group LLC is pleased to welcome Mr. Michael O’Donnell. Michael will be a member of the Product Development Team (PDT). He will assist be working on the improvement of the TDACS graphic environment and the implementation of various simulations protocols.
                                            <br /><br />
                                            Märzen Group LLC is pleased to welcome Mr. Tommy VanTilborg. Tommy works as a Systems Engineer in the Engineering Support Team (EST). and performs engineering activities in support of the Missile Defense Agency IRES contract supporting COCOM and War Fighter Exercises effort.
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}