import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import pdf from '../media/Instructions.pdf'

export default function Instructions() {

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <Container fluid>
                    <div className='greyHeader'>
                        <h1>TDACS Helpdesk Instructions</h1>
                    </div>
                    <Container>
                        <Row className='paddingTop'>
                            <Col>
                                <iframe className='pdf' title='TDACS-InstructionsPDF' src={`${pdf}#view=fitH`} />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}