import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner, Image } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import DODLogo from '../images/logos/DOD-logo.png'
import barleyLogo from '../images/logos/barley-logo.jpg'

export default function InterestingLinks() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1>Links of Interest</h1>
                        </div>
                        <Container>
                            <Row className='paddingTop'>
                                <Col md={4}>
                                    <h2>Information Technology Links</h2>
                                    <Image width='250px' height='200px' fluid src={DODLogo} alt='Department of Defense Seal' />
                                </Col>
                                <Col md={8}>
                                    <ul>
                                        <li>
                                            <a href='https://www.defense.gov/' target='_blank' rel='noreferrer'>United States Department of Defense (DOD)</a>
                                        </li>
                                        <li>
                                            <a href='https://www.mda.mil/' target='_blank' rel='noreferrer'>United States Missile Defense Agency (MDA)</a>
                                        </li>
                                        <li>
                                            <a href='https://www.nga.mil/index.html' target='_blank' rel='noreferrer'>National Geospatial-Intelligence Agency (NGA)</a>
                                        </li>
                                        <li>
                                            <a href='https://www.navy.com/' target='_blank' rel='noreferrer'>United States Navy (USN)</a>
                                        </li>
                                        <li>
                                            <a href='https://www.nsa.gov/' target='_blank' rel='noreferrer'>National Security Agency (NSA)</a>
                                        </li>
                                        <li>
                                            <a href='https://www.disa.mil/' target='_blank' rel='noreferrer'>Defense Information Systems Agency (DISA)</a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md={4}>
                                    <h2>Vendor Links</h2>
                                </Col>
                                <Col md={8}>
                                    <ul>
                                        <li>
                                            <a href='https://www.microsoft.com/en-us/windows/' target='_blank' rel='noreferrer'>Microsoft Windows</a>
                                        </li>
                                        <li>
                                            <a href='https://docs.microsoft.com/en-us/' target='_blank' rel='noreferrer'>Microsoft Developers Tools</a>
                                        </li>
                                        <li>
                                            <a href='https://support.microsoft.com/?ln=en-id' target='_blank' rel='noreferrer'>Microsoft Support</a>
                                        </li>
                                        <li>
                                            <a href='https://support.microsoft.com/en-us/windows/windows-update-faq-8a903416-6f45-0718-f5c7-375e92dddeb2' target='_blank' rel='noreferrer'>Microsoft Update: FAQ</a>
                                        </li>
                                        <li>
                                            <a href='https://www.microsoft.com/en-us/security/business/compliance/information-protection' target='_blank' rel='noreferrer'>Microsoft Security</a>
                                        </li>
                                        <li>
                                            <a href='https://www.microsoft.com/en-us/download/default.aspx' target='_blank' rel='noreferrer'>Microsoft Download Center</a>
                                        </li>
                                        <li>
                                            <a href='https://www.microsoft.com/en-us/microsoft-365?legRedir=default&CorrelationId=2f45929e-0a94-41b7-829c-a4971a9877f6&rtc=1' target='_blank' rel='noreferrer'>Microsoft Office Update</a>
                                        </li>
                                        <li>
                                            <a href='https://www.microsoft.com/en-us/msrc' target='_blank' rel='noreferrer'>Microsoft Security Response Center</a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md={4}>
                                    <h2>Brewing Information Links</h2>
                                    <Image height='200' width='200' fluid src={barleyLogo} alt='Marzen Group logo with barley and hops in the background' />
                                </Col>
                                <Col md={8}>
                                    <ul>
                                        <li>
                                            <a href='https://www.beermapping.com/beerroutes/' target='_blank' rel='noreferrer'>Beer Mapping Project - USA</a>
                                        </li>
                                        <li>
                                            <a href='https://www.beeradvocate.com/' target='_blank' rel='noreferrer'>The Beer Advocate</a>
                                        </li>
                                        <li>
                                            <a href='http://www.beerinstitute.org/' target='_blank' rel='noreferrer'>The Beer Institute</a>
                                        </li>
                                        <li>
                                            <a href='http://www.greatbeer.co.uk/' target='_blank' rel='noreferrer'>Beer and Pubs - UK</a>
                                        </li>
                                        <li>
                                            <a href='http://www.ratebeer.com/Brewery-Directory.asp' target='_blank' rel='noreferrer'>International Brewers Directory</a>
                                        </li>
                                        <li>
                                            <a href='https://camra.org.uk/' target='_blank' rel='noreferrer'>Campaign for Real Ale (CAMRA)</a>
                                        </li>
                                        <li>
                                            <a href='https://whatpub.com/' target='_blank' rel='noreferrer'>CAMRA Pubs</a>
                                        </li>
                                        <li>
                                            <a href='https://www.oktoberfest.de/' target='_blank' rel='noreferrer'>Oktoberfest Homepage</a>
                                        </li>
                                        <li>
                                            <a href='http://www.brewersofeurope.org/' target='_blank' rel='noreferrer'>Brewers of Europe</a>
                                        </li>
                                        <li>
                                            <a href='http://www.belgianbeerfactory.com/industry/famous-belgian-beer-brands/' target='_blank' rel='noreferrer'>Belgian Beer Brands</a>
                                        </li>
                                        <li>
                                            <a href='http://www.worldbrewingcongress.org/Pages/default.aspx' target='_blank' rel='noreferrer'>World Brewing Congress</a>
                                        </li>
                                        <li>
                                            <a href='https://www.nederlandsebiercultuur.nl/' target='_blank' rel='noreferrer'>Biers of Holland</a>
                                        </li>
                                        <li>
                                            <a href='https://www.siebelinstitute.com/' target='_blank' rel='noreferrer'>Siebel Institute of Technology</a>
                                        </li>
                                        <li>
                                            <a href='https://www.weihenstephaner.com/' target='_blank' rel='noreferrer'>Weilhenstephan Brewery School</a>
                                        </li>
                                        <li>
                                            <a href='https://www.mbaa.com/publications/tq/Pages/default.aspx' target='_blank' rel='noreferrer'>Master Brewers Association of The Americas (MBAA)</a>
                                        </li>
                                        <li>
                                            <a href='https://www.asbcnet.org/Pages/default.aspx' target='_blank' rel='noreferrer'>American Society of Brewing Chemists (ASBC)</a>
                                        </li>
                                    </ul>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}