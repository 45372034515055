import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/services/cst-banner.jpg'
import LakeErie from '../../images/services/USS-Lake-Erie.jpg'
import c2bmc from '../../images/services/icons/c2bmc-icon.png'

export default function CST() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1><Image src={c2bmc} className='icon' alt='a graphic of a globe with a satelite' />C2BMC Support Team</h1>
                            <h2>Providing system engineering and integration</h2>
                        </div>
                        <Image src={header} width='1200' height='400' fluid className='banner' alt='A rocket launching' />
                        <Container>
                            <Row className='paddingTop'>
                                <Col lg={4}>
                                    <p>
                                        The C2BMC Support Team (CST) provides systems engineering and consulting support to the Command and Control Ballistic Missile Contract (C2BMC) that is managed by the Missile Defense Agency (MDA). This Märzen Group team supports the C2BMC development efforts with design guidance for both testing and operations of the Link 16 networks, design consultation for setting up and isolating networks for flight and ground tests, analyzing requirements for Operational Task Orders for Link 16 networks, and providing design guidance for Satellite Communications.
                                    </p>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <Image className='greyBackground'  width='600' height='400' src={LakeErie} fluid alt='USS Lake Erie' />
                                    <p className='caption'>"The appearance of U.S. Department of Defense (DoD) visual information does not imply or constitute DoD endorsement." Photo courtesy of U.S. Navy, Mass Communication Specialist 2nd Class Paul D. Honnick, "ID 080626-N-6674H-048", 2008. No Copyright, See Commons: <a href='https://creativecommons.org/licenses/by-nd/1.0/legalcode'>Licensing</a>, <a href='https://commons.wikimedia.org/w/index.php?curid=8182678'>Source</a></p>
                                </Col>
                                <Col xs={12} md={6} lg={4}>
                                    <p>
                                        The CST also assists in the development of concepts of operation, assists in the proper collection of flight and ground test data, reviews and analyzes data and provides comparisons to model results, documents analysis and recommend changes to designs and/or models, and prepares briefings for Command level decisions. In addition, the CST provides support for C2BMC system deployment and site activation, system sustainment, and Cybersecurity.
                                    </p>
                                </Col>
                            </Row>
                            <Row className='paddingTop'>
                                <Col>
                                    <p>
                                        The CST provides integration and test capabilities to included support for pre-test coordination, participation in and monitoring of test events, and post-test analysis/reporting activities related to ground and flight tests. CST members brief Government management personnel on technical issues found during ground and flight tests. They also make recommendations for improved performance in the future. They help Government leaders to establish corrective action priorities with respect to technical needs, funding availability, and scheduled constraints.
                                    </p>
                                    <p>
                                        The CST provides operational sustainment and system deployment capabilities to include those items listed above, as well as developing, engineering, furnishing, installing, testing, and maintaining the BCN Core Network, Link 16 Gateway, BCN Security and BCN Network Management monitoring, management and control tools for the BNOSC-C.
                                    </p>
                                    <p>
                                        The CST provides integration and test capabilities to included support for pre-test coordination, participation in and monitoring of test events, and post-test analysis/reporting activities related to ground and flight test.  CST members brief Government management personnel on technical issues found during ground and flight tests.  They also make recommendations for improved performance in the future.  They help Government leaders to establish corrective action priorities with respect to technical need, funding availability and scheduled constraints.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}