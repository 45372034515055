import React, { useState, useEffect } from 'react'
import { Container, Spinner, Image, Table, Row, Col } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/products/sff-banner.jpg'
import sff from '../../images/products/SFF-behind.JPG'
import icon from '../../images/services/icons/cpt-icon.png'

export default function SFF() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1><Image src={icon} className='icon' alt='a graphic of a blue monitor with a picture of lines connected on the monitor' />SFF:</h1>
                            <h2>Small Form Factor</h2>
                        </div>
                        <Image src={header} fluid width='1200' height='400' className='banner' alt='Blue fades in on the sides with a SFF in the middle' />
                        <Container>
                            <Row className='paddingTop'>
                                <Col sm={12} md={6}>
                                    <p>
                                        The SFF is the Small Form Factor and is designed to handle those user requirements that put a premium on space utilization and limitations on functionality and display. This fully functional TDACS unit has been used successfully in operational platforms where space is at a premium. 
                                    </p>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className='greyBackground'>
                                        <Image src={sff} width='600px' height='400px' fluid className='center' alt='the back panel of a SFF' />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Tech Specs:</th>
                                                <th>Form Factor</th>
                                                <th>Monitor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Overall Length</td>
                                                <td>10"</td>
                                                <td>X</td>
                                            </tr>
                                            <tr>
                                                <td>Overall Width</td>
                                                <td>5.8"</td>
                                                <td>X</td>
                                            </tr>
                                            <tr>
                                                <td>Overall Height</td>
                                                <td>2.8"</td>
                                                <td>X</td>
                                            </tr>
                                            <tr>
                                                <td>Weight (lbs.)</td>
                                                <td>3.45 lbs</td>
                                                <td>X</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs={12}>
                                    <Table striped bordered responsive='sm'>
                                        <thead>
                                            <tr>
                                                <th>Characteristic</th>
                                                <th colSpan={2}>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Power Requirements</td>
                                                <td colSpan={2}>1 Three-Pronged 120 VAC outlet (SFF)</td>
                                            </tr>
                                            <tr>
                                                <td>Power Consumption</td>
                                                <td>Idle: 1.22 Watts, 4.16 BTU/hr</td>
                                                <td>Max: 39 Watts, 133 BTU/hr</td>
                                            </tr>
                                            <tr>
                                                <td>Temperature</td>
                                                <td>Operating: 32º - 104ºF</td>
                                                <td>Operating: 0º - 40ºC</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}