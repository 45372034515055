import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Table, Spinner } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

export default function Rates() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1>Märzen - GSA Labor Catalog</h1>
                        </div>
                        <Container>
                            <Row className='paddingTop'>
                                <Col>
                                    <p>On 25 March 2020, Marzen Group LLC was awarded contract 47QTCA20D007V by the General Services Administration (GSA). This contract includes negotiated terms for labor rates that may be used by most agencies of the Federal Government. For information on the terms of Eligibility for the Use of GSA Sources of Supply and Services refer to GSA Order ADM 4800.2E.</p>
                                    <p>As part of contract 47QTCA20D007V Märzen Group LLC has established negotiated terms and conditions and competitive labor rates in the following general categories and established an Authorized MAS Schedule Pricelist. The negotiated rates provided are categorized into four engineering disciplines: <a href='#engineering'>Engineer</a>, <a href='#systems'>Systems Engineer</a>, <a href='#data-link'>Data Link Engineer</a>, and <a href='#test'>Test Engineer</a>.</p>
                                    <p>The negotiated rates are for both works performed at the contractor site and government facilities.</p>
                                    <p>For additional information on Märzen Group labor rates - please contact us at: <a href='mailto:info@marzen.com'>info@marzen.com</a></p>
                                </Col>
                            </Row>

                            <div className='divRates'>
                                <h2>GSA Contract 47QTCA20D007V</h2>
                                <h3>Combined Labor Rates</h3>
                                <p>(MAR 2021 - MAR 2025)</p>
                            </div>
                            <Row>
                                <Col xs={12} md={6}>
                                    <h3 className='h3Rates'>Engineering</h3>
                                    <Table striped size='sm' bordered>
                                        <thead>
                                            <tr>
                                                <th>Labor Category</th>
                                                <th>GSA Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Engineer 1</td>
                                                <td>$129.52</td>
                                            </tr>
                                            <tr>
                                                <td>Engineer 2</td>
                                                <td>$171.26</td>
                                            </tr>
                                            <tr>
                                                <td>Engineer 3</td>
                                                <td>$190.28</td>
                                            </tr>
                                            <tr>
                                                <td>Engineer 4</td>
                                                <td>$209.32</td>
                                            </tr>
                                            <tr>
                                                <td>IT Technician 2</td>
                                                <td>$94.62</td>
                                            </tr>
                                            <tr>
                                                <td>IT Technician 3</td>
                                                <td>$111.32</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs={12} md={6}>
                                    <h3 className='h3Rates'>Systems Engineering</h3>
                                    <Table striped size='sm' bordered>
                                        <thead>
                                            <tr>
                                                <th>Labor Category</th>
                                                <th>GSA Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Systems Engineer 1</td>
                                                <td>$76.94</td>
                                            </tr>
                                            <tr>
                                                <td>Systems Engineer 2</td>
                                                <td>$83.64</td>
                                            </tr>
                                            <tr>
                                                <td>Systems Engineer 3</td>
                                                <td>$133.71</td>
                                            </tr>
                                            <tr>
                                                <td>Systems Engineer 4</td>
                                                <td>$167.60</td>
                                            </tr>
                                            <tr>
                                                <td>Subject Matter Expert (SME)</td>
                                                <td>$174.62</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={12} md={6}>
                                    <h3 className='h3Rates'>Data Link Engineering</h3>
                                    <Table striped size='sm' bordered>
                                        <thead>
                                            <tr>
                                                <th>Labor Category</th>
                                                <th>GSA Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Data Link Engineer 1</td>
                                                <td>$75.05</td>
                                            </tr>
                                            <tr>
                                                <td>Data Link Engineer 2</td>
                                                <td>$97.05</td>
                                            </tr>
                                            <tr>
                                                <td>Data Link Engineer 3</td>
                                                <td>$140.41</td>
                                            </tr>
                                            <tr>
                                                <td>Data Link Engineer 4</td>
                                                <td>$168.23</td>
                                            </tr>
                                            <tr>
                                                <td>Data Link SME 1</td>
                                                <td>$181.18</td>
                                            </tr>
                                            <tr>
                                                <td>Data Link SME 2</td>
                                                <td>$187.65</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>

                                <Col>
                                    <h3 className='h3Rates'>Test Engineering</h3>
                                    <Table striped size='sm' bordered>
                                        <thead>
                                            <tr>
                                                <th>Labor Category</th>
                                                <th>GSA Rate</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Test Engineer 2</td>
                                                <td>$99.14</td>
                                            </tr>
                                            <tr>
                                                <td>Test Engineer 3</td>
                                                <td>$112.97</td>
                                            </tr>
                                            <tr>
                                                <td>Test Engineer 4</td>
                                                <td>$138.33</td>
                                            </tr>
                                            <tr>
                                                <td>Test Engineer 5</td>
                                                <td>$161.39</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}