import React, { useState, useEffect } from 'react'
import { useSpring, animated, config } from 'react-spring'
import { Container, Image, ListGroup, Spinner, Row, Col } from 'react-bootstrap'

import sftwr from '../../images/services/icons/sftwr.png'
import header from '../../images/banners/products/sftwr-banner1.jpg'
import connection from '../../images/connection.png'
import tmi from '../../images/products/software/TMI.gif'
import threat from '../../images/products/software/adjudication.gif'
import dataFwd from '../../images/products/software/data-fwding.gif'

export default function Software() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1><Image src={sftwr} className='icon' alt='a graphic of a the earth with three computers inside' />TDACS Software</h1>
                            <h2>Software tools constructed to support connectivity</h2>
                        </div>
                        <Image className='banner' src={header} width='1200' height='400' fluid alt='a screenshot of TDACS software of a missle' />
                        <Container>
                            <Row className='paddingTop'>
                                <Col lg={12}>
                                    <h2>What does TDACS do?</h2>
                                    <p>
                                        To enhance user-friendliness and minimize training requirements the TDACS application software is directly interfaced with Microsoft Office 2016 products. Specifically, the Office applications Excel, PowerPoint, and Visio are used to provide visual tools for real-time data analysis, data presentation, and network management of the data gathered on the TDACS datalink connections. All TDL message data on each TDACS connection can be recorded in raw format and/or time-tagged and stored in the TDACS SQL database. TDACS can also display TDL data processed from its connections on both 2D and 3D maps. These TDACS map displays can present data in real-time as it is received on the TDACS connections, and it can display data played back from recordings or high-speed queries to the TDACS SQL databases.
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <Image fluid src={connection} width='600' height='600' alt='a graphic of earth with curved lines across the surface' />
                                    <p className='bold'>TDACS is capable of hosting, monitoring and connecting to multiple systems.</p>
                                </Col>
                                <Col md={6}>
                                    <h2>TDACS Connection Capability:</h2>
                                    <p>
                                        MIL-STD-6016 and MIL-STD-3011 are the primary data link specifications used within TDACS, but depending on end-user needs, TDACS provides multilink compatibility with other datalink and simulation protocols using software plug-ins.
                                        TDACS is an operator-controlled workstation that provides multiple data link capabilities necessary to effectively operate, manage, and analyze a Tactical Data Link (TDL) network or multiple networks.  TDACS also provides software tools that can ensure the integrity of link messages in accordance with MILSTDs.  With the aid of external equipment, radar, and TDL information, the TDACS accomplishes the mission by performing the following functions.
                                    </p>
                                    <p>
                                        A TDACS design goal for each data connection type is to provide all the following basic connection functions. However, some connection types do not or cannot support all these functions:
                                    </p>
                                    <ListGroup variant='flush'>
                                        <ListGroup.Item>Message Routing and Forwarding</ListGroup.Item>
                                        <ListGroup.Item>Data Monitoring and Analysis</ListGroup.Item>
                                        <ListGroup.Item>Data Manipulation</ListGroup.Item>
                                        <ListGroup.Item>Data Filtering</ListGroup.Item>
                                        <ListGroup.Item>Data Recording and Playback</ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md={6}>
                                    <h2>TDACS Connection Types:</h2>
                                    <h3>Standard Edition</h3>
                                    <ListGroup variant='flush'>
                                        <ListGroup.Item>TDACS MIL-STD 3011C JREAP-C </ListGroup.Item>
                                        <ListGroup.Item>TDACS STANAG 5602 Ed 2 </ListGroup.Item>
                                        <ListGroup.Item>TDACS DIS Protocol </ListGroup.Item>
                                        <ListGroup.Item>TDACS MTDS Serial J</ListGroup.Item>
                                        <ListGroup.Item>TDACS Network Monitor</ListGroup.Item>
                                        <ListGroup.Item>TDACS MTC Message Protocol</ListGroup.Item>
                                        <ListGroup.Item>TDACS Network Address Translator</ListGroup.Item>
                                        <ListGroup.Item>TDACS SYSLOG Message Protocol</ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col md={6}>
                                    <h2>Optional TDACS Connection Types:</h2>
                                    <ListGroup variant='flush'>
                                        <ListGroup.Item>TDACS SISO JTIDS DIS Protocol</ListGroup.Item>
                                        <ListGroup.Item>TDACS Satellite J Protocol</ListGroup.Item>
                                        <ListGroup.Item>TDACS Passive Ethernet Tap [PTap]</ListGroup.Item>
                                        <ListGroup.Item>TDACS Link 11A NTDS/Link 16 Data Forwarding</ListGroup.Item>
                                        <ListGroup.Item>TDACS MIL-STD 3011D JREAP-C</ListGroup.Item>
                                        <ListGroup.Item>TDACS MIL-STD 3011B JREAP-A</ListGroup.Item>
                                        <ListGroup.Item>TDACS Dynamic Scenario Injector-Link [DSI-L]</ListGroup.Item>
                                        <ListGroup.Item>TDACS B Series, VMF, and XML Message Suite </ListGroup.Item>
                                        <ListGroup.Item>TDACS MPEG2 TS/KLV to Link16 Forwarding</ListGroup.Item>
                                        <ListGroup.Item>TDACS MIDS LVT2/2M ADDSI X.25</ListGroup.Item>
                                        <ListGroup.Item>TDACS MIDS LVT5/MOS/SHIP 2H</ListGroup.Item>
                                        <ListGroup.Item>TDACS MIDS LVT1 Terminal Platform A and D v5.6 Plug-in License</ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div className='center'>
                                        <Image fluid src={dataFwd} width='900px' height='300' alt='a screenshot of the data-forwarding types and connections in TDACS' className='greyBackground' />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Image fluid src={tmi} width='600px' height='400px' className='greyBackground' alt='a screenshot of TDACS TMI software' />
                                </Col>
                                <Col md={6}>
                                    <h2>Tactical Message Injector (TMI):</h2>
                                    <p>
                                        TDACS provides the operator with the ability to create and transmit all types of TADIL J messages in accordance with MIL-STD-6016F to TDACS connections selected by the operator. The TMI capability has been implemented as a set of software plug-ins for the TDACS TacMap 3D is made up of four functions:
                                    </p>
                                    <ListGroup variant='flush'>
                                        <ListGroup.Item>Code Word Blaster</ListGroup.Item>
                                        <ListGroup.Item>Quick Shots</ListGroup.Item>
                                        <ListGroup.Item>Static Scenario Generation</ListGroup.Item>
                                        <ListGroup.Item>Dynamic Scenario Generation</ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <h2>TDACS Adjudication:</h2>
                                    <p>
                                        The TDACS Adjudication plug-in provides a combined analysis capability that uses a variety of payloads (Link 16 and DIS) to formulate an accurate understanding of a preplanned scenario. The tool provides the capability to resolve conflicts by defining true interoperability and enumeration issues between units and provides a clear analysis of tracking, reporting, and engagement of threats. Adjudication involves performance statistics, reports, engagements, and other adverse effects on exercise success. At the end of the day, the tool provides automated reports and detailed issues to AAR personnel.
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <Image fluid src={threat} width='600px' height='400px' className='greyBackground' alt='a screenshot of TDACS Adjudication' />
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )

}