import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import oktoberfest from '../images/COS.jpg'
import bierSign from '../images/we-be-bier.png'

export default function About() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1>History of Märzen</h1>
                        </div>
                        <Container>
                            <Row >
                                <Col sm={12}>
                                    <h2 id='homePgQ'>Who's Märzen Group LLC?</h2>
                                </Col>
                                <Col id='homePgDesc'>
                                    <p>
                                        Märzen Group LLC is a small business founded in 1999 out of Nashua, New Hampshire. Märzen provides consulting services and products to government and commercial customers around the globe. Our consulting services cover a wide range of competencies in System Engineering and Integration Services, Product Development, Testing, and Deployment, used by our customers daily.
                                    </p>
                                    <p>
                                        Märzen professional staff support commercial, non-profit, and government organizations in diverse areas. Such as the development of real-time, object-oriented software for Tactical Military Communications, the analysis of Network Security for Command and Control Systems, and the requirements analysis for new Windows platform applications for hospitals and small companies.
                                    </p>
                                    <p>
                                        We are excited about our work and enjoy bringing customers to new levels of support and satisfaction. We intend to continue to spread our enthusiasm in every project we take on.
                                    </p>
                                </Col>
                            </Row>
                            <Row className='paddingTop'>
                                <Col xs={12} sm={7} xl={7}>
                                    <p>
                                        The name, Märzen, stems from our founders, Doug Morris and Bob Blaisdell. It derives from their long-standing interest and planned participation in the beer brewing industry.
                                    </p>
                                    <p>
                                        "Märzen" is a German word that roughly translates to "from the Month of March." But perhaps more importantly, Märzen is also the name for the style of lager beer that derives its name from the month in which its traditionally brewed. Today the Märzen style is famous because it's the beer that's served at the Oktoberfest in Munich, Germany.
                                    </p>
                                </Col>
                                <Col xs={12} sm={5} xl={5}>
                                    <Image className='greyBackground' width='600px' height='400px' src={oktoberfest} alt='COS employees at Oktoberfest' fluid />
                                    <p className='caption'>Colorado Springs employees enjoying Oktoberfest in 2022</p>
                                </Col>
                            </Row>
                            <Row className='blueBackground'>
                                <Col>
                                    <h2 className='white'>Our Mission - </h2>
                                    <h3>System Engineering and Consulting Services</h3>
                                    <hr className='white' />
                                    <p>
                                        Märzen Group LLC is committed to supplying our Customers with World-Class System Integration & Engineering Consulting Services and State-of-the-Art Products in a timely and cost-effective manner.
                                    </p>
                                    <p>
                                        Märzen continually strives to raise the level of excellence for the products and services we provide to our customers over and above the existing marketplace norms. Our definition of success in this market is when our customers can leverage a significant competitive advantage in their business environment resulting from the products and services we provide.
                                    </p>
                                    <p>
                                        If you have any questions about our work or want to discuss our services in more detail - contact us at: <a id='homeEmail' href='mailto:info@marzen.com'>info@marzen.com</a>. We are happy to discuss how we might be able to support you.
                                    </p>
                                </Col>
                                <div className='center'>
                                    <Image fluid width='300' height='300px' src={bierSign} alt='a sign outside the märzen building that reads we be bier' />
                                </div>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}