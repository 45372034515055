import React, { useState, useEffect } from 'react'
import { Container, Spinner, Button, Row, Col, Modal } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import BugReport from './BugReport'
import EnhancementReport from './EnhancementReport'
import Helpdesk from './Helpdesk'
import RegistrationReport from './RegistrationReport'

export default function TechReportType() {
    const [flip, set] = useState(false)

    const [showBug, setShowBug] = useState(false);
    const handleCloseBug = () => setShowBug(false);
    const handleShowBug = () => setShowBug(true);

    const [showRegistration, setShowRegistration] = useState(false);
    const handleCloseRegistration = () => setShowRegistration(false);
    const handleShowRegistration = () => setShowRegistration(true);

    const [showEnhancement, setShowEnhancement] = useState(false);
    const handleCloseEnhancement = () => setShowEnhancement(false);
    const handleShowEnhancement = () => setShowEnhancement(true);

    const [showHelpdesk, setShowHelpdesk] = useState(false);
    const handleCloseHelpdesk = () => setShowHelpdesk(false);
    const handleShowHelpdesk = () => setShowHelpdesk(true);

    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1>TDACS Technical Support</h1>
                            <h2>Are you looking to have your machine serviced? Or do you have questions about setup?</h2>
                        </div>
                        <Container>
                            <Row className='paddingTop'>
                                <Col>
                                    <h3 className='blueBackground'>Let us help you - TDACS Helpdesk Instructions:</h3>
                                    <div className='helpDeskList' >
                                        <ul>
                                            <li>TDACS Bug Reports / Enhancement Requests from TDACS users are encouraged.</li>
                                            <li className='paddingTop'>We want to hear from you about:</li>
                                            <ul>
                                                <li>TDACS problems you have found.</li>
                                                <li>TDACS improvements you would like to have.</li>
                                            </ul>
                                            <li className='paddingTop'>The TDACS Helpdesk is a paid subscription service [TDACS SKU: 34101].</li>
                                            <li className='paddingTop'>The <a href='/helpdesk-instructions'>TDACS Helpdesk Instructions</a> provide the details on TDACS Helpdesk support.</li>
                                            <li className='paddingTop'>Phone support for Helpdesk subscribers is available Mon-Fri, 0800-1700 Eastern Time.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className='helpDeskContact'>
                                        <h2 className='center'>What type of support ticket are you submitting?</h2>
                                        < hr />
                                        <div id='reportQs'>
                                            {/* TDACS BUG REPORT */}
                                            <Button variant='warning' className='marginTop' value='bug' onClick={handleShowBug}>TDACS Bug Report</Button>
                                            <br />
                                            <Modal
                                                show={showBug}
                                                onHide={handleCloseBug}
                                                backdrop="static"
                                                keyboard={false}
                                                size="lg"
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>TDACS Bug Report</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <BugReport />
                                                </Modal.Body>
                                            </Modal>


                                            {/* TDACS REGISTRATION CODE */}
                                            <Button variant='warning' className='marginTop' onClick={handleShowRegistration}>TDACS Registration Code</Button>
                                            <br />

                                            <Modal
                                                show={showRegistration}
                                                onHide={handleCloseRegistration}
                                                backdrop="static"
                                                keyboard={false}
                                                size="lg"
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>TDACS Registration Code Request</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <RegistrationReport />
                                                </Modal.Body>
                                            </Modal>

                                            {/* TDACS ENHANCEMENT REQEST */}
                                            <Button variant='warning' className='marginTop' onClick={handleShowEnhancement}>TDACS Enhancement Request</Button>
                                            <br />

                                            <Modal
                                                show={showEnhancement}
                                                onHide={handleCloseEnhancement}
                                                backdrop="static"
                                                keyboard={false}
                                                size="lg"
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Enhancement Request</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <EnhancementReport />
                                                </Modal.Body>
                                            </Modal>


                                            {/* TDACS HELPDESK */}
                                            <Button variant="warning" className='marginTop' onClick={handleShowHelpdesk}> TDACS Helpdesk</Button>

                                            <Modal
                                                show={showHelpdesk}
                                                onHide={handleCloseHelpdesk}
                                                backdrop="static"
                                                keyboard={false}
                                                size="lg"
                                                aria-labelledby="contained-modal-title-vcenter"
                                                centered
                                            >
                                                <Modal.Header closeButton>
                                                    <Modal.Title>TDACS Helpdesk Request</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <Helpdesk />
                                                </Modal.Body>
                                            </Modal>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}