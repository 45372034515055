import React from 'react'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import logo from '../images/logos/logo.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

export default function NavBar() {
  return (
    <Navbar collapseOnSelect expand='lg'>
      <Navbar.Brand>
        <img src={logo} width='196px' height='75px' className="d-inline-block align-top" alt='Märzen Group logo' aria-label='Home Button' />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls='responsive-navbar-nav'>
        <FontAwesomeIcon icon={faBars} size='lg' />
      </Navbar.Toggle>
      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav variant='tabs'>
        <Nav.Link href='/'>Home</Nav.Link>
          <NavDropdown title='Products'>
            <NavDropdown.Item href='/products'>TDACS Overview</NavDropdown.Item>
            <NavDropdown className='moboPad' title='Hardware'>
              {/* <NavDropdown.Item disabled>TDACS Form Factors</NavDropdown.Item> */}
              <NavDropdown.Item href='/crf'>CRF Overview</NavDropdown.Item>
              <NavDropdown.Item href='/srf'>SRF Overview</NavDropdown.Item>
              <NavDropdown.Item href='/pff'>PFF Overview</NavDropdown.Item>
              <NavDropdown.Item href='/sff'>SFF Overview</NavDropdown.Item>
              <NavDropdown.Item href='/iff'>IFF Overview</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className='moboPad' title='Software'>
              {/* <NavDropdown.Item disabled>Märzen Software</NavDropdown.Item> */}
            <NavDropdown.Item href='/software'>TDACS Software Suite</NavDropdown.Item>
            <NavDropdown.Item href='/tdacs-eula'>End User License Agreement</NavDropdown.Item>
            </NavDropdown>
          </NavDropdown>
          <NavDropdown title='Services'>
            <NavDropdown.Item href='/services'>Services Overview</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href='/est'>Engineering Services Team</NavDropdown.Item>
            <NavDropdown.Item href='/pss'>Production Support Services</NavDropdown.Item>
            <NavDropdown.Item href='/pdt'>Product Development Team</NavDropdown.Item>
            <NavDropdown.Item href='/cst'>C2BMC Support Team</NavDropdown.Item>
            <NavDropdown.Item href='/sst'>Support Services Team</NavDropdown.Item>
            <NavDropdown.Item href='/jtids'>JTIDS/Link 16 Support</NavDropdown.Item>
            <NavDropdown.Item href='/west'>Windows Enterprise Support</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title='About'>
            <NavDropdown.Item href='/about'>About Us</NavDropdown.Item>
            <NavDropdown.Item href='/interesting-links'>Links of Interest</NavDropdown.Item>
            <NavDropdown.Item href='/contact'>Contact Us</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href='/employment'>Employment</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href='/news'>News</Nav.Link>
          <NavDropdown title='Contract Information'>
            <NavDropdown.Item href='/admin-info'>Administration Contract Info</NavDropdown.Item>
            <NavDropdown.Item href='/seaport'>Seaport-NxG Contract Information</NavDropdown.Item>
            <NavDropdown.Item href='/contract'>GSA Contract Data</NavDropdown.Item>
            <NavDropdown.Item href='/rates'>GSA Rates Info</NavDropdown.Item>
            <NavDropdown.Item href='/pricelist'>US Commercial Price List</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Nav.Link className='ml-auto' id='moboCtr' href='/helpdesk'>TDACS Technical Support</Nav.Link>
      </Navbar.Collapse>
    </Navbar>
  )
}