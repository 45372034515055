import React, { useState, useEffect } from 'react'
import { Container, Spinner, Image, Table, Row, Col } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import header from '../../images/banners/products/crf-banner.jpg'
import crf from '../../images/products/CRF-angle.jpg'
import icon from '../../images/services/icons/cpt-icon.png'

export default function CRF() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1><Image src={icon} className='icon' alt='a graphic of a blue monitor with a picture of lines connected on the monitor' />CRF:</h1>
                            <h2>Combined Rack Factor</h2>
                        </div>
                        <Image src={header} fluid width='1200' height='400' className='banner' alt='Blue fades in on the sides with a CRF in the middle' />
                        <Container>
                            <Row className='paddingTop'>
                                <Col sm={12} md={6}>
                                    <p>
                                        The CRF is the new TDACS Combined Rack Form Factor (CRF) workstation. The new CRF workstation is a 4U high ATX compliant format that is equally suited for either a desktop or a rack-mounted operation location. CRF can be placed in any standard 19” rack equipment bay and operated remotely via a customer-provided KVM switch environment. This form factor can support multiple high-resolution monitors and all special interface cards supported by TDACs. The Desktop workstation form factor is for those users without space limitations that usually work in either exercise suites or laboratory environments.
                                    </p>
                                </Col>
                                <Col sm={12} md={6}>
                                    <div className='greyBackground'>
                                        <Image src={crf} width='600px' height='400px' fluid className='center' alt='the side view of a CRF' />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Table striped bordered>
                                        <thead>
                                            <tr>
                                                <th>Tech Specs:</th>
                                                <th>Form Factor</th>
                                                <th>Monitor(s)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Overall Length</td>
                                                <td>17.5"</td>
                                                <td>20"</td>
                                            </tr>
                                            <tr>
                                                <td>Overall Width</td>
                                                <td>19"</td>
                                                <td>3"</td>
                                            </tr>
                                            <tr>
                                                <td>Overall Height</td>
                                                <td>7" (4U)</td>
                                                <td>18"</td>
                                            </tr>
                                            <tr>
                                                <td>Weight (lbs.)</td>
                                                <td>N/A</td>
                                                <td>N/A</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col xs={12}>
                                    <Table striped bordered responsive='sm'>
                                        <thead>
                                            <tr>
                                                <th>Characteristic</th>
                                                <th colSpan={2}>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Power Requirements</td>
                                                <td>1 Three-Pronged 120 VAC outlet (CRF)</td>
                                                <td>2 Three-Pronged 120 VAC outlet (Monitors)</td>
                                            </tr>

                                            <tr>
                                                <td>Power Consumption</td>
                                                <td>Idle: 101.3 Watts, 345.5 BTU/hr</td>
                                                <td>Max: 326.3 Watts, 1113 BTU/hr</td>
                                            </tr>
                                            <tr>
                                                <td>Temperature</td>
                                                <td>Operating: 32º - 104ºF</td>
                                                <td>Operating: 0º - 40ºC</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}