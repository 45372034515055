import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Spinner, Image } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone, faFax, faMapPin } from '@fortawesome/free-solid-svg-icons'

import building from '../images/building.JPG'
import VB from '../images/VB.JPG'

export default function Contact() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1>Contact Us</h1>
                            <h2>Have questions about products and services? Don't hesitate to reach out</h2>
                        </div>
                        <Container>
                            <Row className='paddingTop'>
                                <Col xs={12} lg={4}>
                                    <h2 className='blueHeader'>Corporate Office</h2>
                                    <Image height='600' width='850' src={building} className='greyBackground' alt='The Märzen building' fluid />
                                </Col>
                                <Col xs={12} lg={4}>
                                    <div className='contactInfo'>
                                        <address className='italic'> <FontAwesomeIcon icon={faMapPin} /> Märzen Group LLC <br />24 Railroad Square<br /> Nashua, New Hampshire 03064-2278</address>
                                        <p>Shipping & Receiving POC: Sean Morris</p>
                                        <p><FontAwesomeIcon icon={faEnvelope} /> Email: <a href='mailto:info@marzen.com' >info@marzen.com</a></p>
                                        <p><FontAwesomeIcon icon={faPhone} /> Phone: <a href='tel:(603)889-9522'>(603) 889-9522</a> </p>
                                        <p><FontAwesomeIcon icon={faFax} /> Fax: <a href='tel:(603)215-2967'>(603) 215-2967</a> </p>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <iframe title='Map to Headquarters' className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2929.1105404557948!2d-71.4680698843534!3d42.76486741743289!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e3b12883e017a9%3A0x9f865699534d8ad3!2s24%20Railroad%20Square%2C%20Nashua%2C%20NH%2003064!5e0!3m2!1sen!2sus!4v1633621249424!5m2!1sen!2sus" allowfullscreen="yes" loading="lazy"></iframe>
                                </Col>
                            </Row>
                            <hr />
                            <Row className='paddingTop'>
                                <Col xs={12} lg={4}>
                                    <h2 className='blueHeader'>Virginia Beach Office</h2>
                                    <Image height='600' width='850' src={VB} className='greyBackground' alt='The Märzen building' fluid />
                                </Col>
                                <Col xs={12} lg={4}>
                                    <div className='contactInfo'>
                                        <address className='italic'> <FontAwesomeIcon icon={faMapPin} /> Märzen Group LLC <br />1206 Laskin Rd, Suite 120<br /> Virginia Beach, Virginia 23451</address>
                                        <p>Shipping & Receiving POC: Tom Dunning</p>
                                        <p><FontAwesomeIcon icon={faPhone} /> Phone: <a href='tel:(757)937-9108'>(757) 937-9108</a> </p>
                                    </div>
                                </Col>
                                <Col xs={4}>
                                    <iframe title='Map to Virginia Beach office' className='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3192.511629102401!2d-76.00068828559716!3d36.854170672361114!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89baebd6aca88223%3A0xe6fa032051065659!2s1206%20Laskin%20Rd%2C%20Virginia%20Beach%2C%20VA%2023451!5e0!3m2!1sen!2sus!4v1650648050741!5m2!1sen!2sus" allowfullscreen="yes" loading="lazy"></iframe>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}