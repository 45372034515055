import React from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import NavBar from './nav/NavBar'
import Home from './nav/Home'
import About from './nav/About'
import InterestingLinks from './nav/InterestingLinks'
import Products from './nav/products/Products'
import Software from './nav/products/Software'
import CRF from './nav/products/CRF'
import SRF from './nav/products/SRF'
import SFF from './nav/products/SFF'
import PFF from './nav/products/PFF'
import IFF from './nav/products/IFF'
import Contract from './nav/contractInfo/Contract'
import AdminInfo from './nav/contractInfo/AdminInfo'
import Rates from './nav/contractInfo/Rates'
import Employment from './nav/Employment'
import Contact from './nav/Contact'
import News from './nav/News'
import Seaport from './nav/contractInfo/Seaport'
import Services from './nav/services/Services'
import EST from './nav/services/EST'
import PSS from './nav/services/PSS'
import PDT from './nav/services/PDT'
import CST from './nav/services/CST'
import SST from './nav/services/SST'
import WEST from './nav/services/WEST'
import JTIDS from './nav/services/JTIDS'
import PriceList from './nav/contractInfo/PriceList'
import EULA from './nav/EULA'
import TechReportType from './nav/reportTypes/TechReportType'
import Instructions from './nav/Instructions'
import Footer from './nav/Footer'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css';

function App() {
  return (
        <div className='d-flex flex-column min-vh-100'>
          <NavBar/>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/about' component={About} />
              <Route path='/contact' component={Contact} />
              <Route path='/interesting-links' component={InterestingLinks} />
              <Route path='/employment' component={Employment} />
              <Route path='/news' component={News} />
              <Route path='/products' component={Products} />
              <Route path='/software' component={Software} />
              <Route path='/crf' component={CRF} />
              <Route path='/srf' component={SRF} />
              <Route path='/sff' component={SFF} />
              <Route path='/pff' component={PFF} />
              <Route path='/iff' component={IFF} />
              <Route path='/services' component={Services} />
              <Route path='/est' component={EST} />
              <Route path='/pss' component={PSS} />
              <Route path='/pdt' component={PDT} />
              <Route path='/cst' component={CST} />
              <Route path='/sst' component={SST} />
              <Route path='/west' component={WEST} />
              <Route path='/jtids' component={JTIDS} />
              <Route path='/admin-info' component={AdminInfo} />
              <Route path='/contract' component={Contract} />
              <Route path='/rates' component={Rates} />
              <Route path='/seaport' component={Seaport} />
              <Route path='/pricelist' component={PriceList} />
              <Route path='/tdacs-eula' component={EULA} />
              <Route path='/helpdesk' component={TechReportType} />
              <Route path='/helpdesk-instructions' component={Instructions} />
              
              <Route path='*'>
                <NoMatch />
              </Route>

            </Switch>
          <Footer />
        </div>
  );
}

function NoMatch() {
  let location = useLocation()
  return (
    <div className='page-container'>
      <div className='error'>
        <h1><FontAwesomeIcon icon={faExclamationTriangle} /> 404 Not Found <FontAwesomeIcon icon={faExclamationTriangle} /></h1>
        <br />
        <h2>Sorry, we can't seem to find a match for '<code>{location.pathname}</code>'</h2>
      </div>
    </div>
  )
}



export default App
