import config from "../../config";
import React, { useState } from 'react'
import { Row, Form, Button } from 'react-bootstrap'
import { useSpring, animated } from "react-spring";

export default function Helpdesk() {

    const [name, setName] = useState('')
    const [tdacs, setTdacs] = useState('')
    const [company, setCompany] = useState('')
    const [job, setJob] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [desc, setDesc] = useState('')
    const reportType = 'TDACS Helpdesk'

    const onNameChange = e => setName(e.target.value)
    const onTdacsChange = e => setTdacs(e.target.value)
    const onCompanyChange = e => setCompany(e.target.value)
    const onJobChange = e => setJob(e.target.value)
    const onPhoneChange = e => setPhone(e.target.value)
    const onEmailChange = e => setEmail(e.target.value)
    const onDescChange = e => setDesc(e.target.value)

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(flip),
    })
    
    const handleClose = () => document.location.reload(true)

    function sendEmail(e) {
        e.preventDefault()
        const data = { name, tdacs, company, job, phone, email, desc, reportType }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        }
        fetch(`${config.API_ENDPOINT}`, requestOptions)
        .then(res =>
            (!res.ok)
                ? res.then(e => Promise.reject(e))
                : console.log(res))
        .then(handleClose)
}

    return (
        <>
            <animated.div style={fade}>

                <div className='helpDeskContact'>
                    <Form onSubmit={sendEmail} name="reportType" value='TDACS Helpdesk'>
                        <Form.Group className='mb-3' controlId='name'>
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                                required
                                placeholder='John Doe'
                                onChange={onNameChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='company'>
                            <Form.Label>Company / Command / Unit Name</Form.Label>
                            <Form.Control
                                required
                                placeholder='Märzen'
                                onChange={onCompanyChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='email'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                required
                                type='email'
                                placeholder='John@marzen.com'
                                onChange={onEmailChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='job'>
                            <Form.Label>Job Title (Optional)</Form.Label>
                            <Form.Control
                                placeholder='Test Engineer'
                                onChange={onJobChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='phone'>
                            <Form.Label>Phone Number (Optional)</Form.Label>
                            <Form.Control
                                type='integer'
                                placeholder='603 889 9522'
                                onChange={onPhoneChange}
                            />
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='tdacs'>
                            <Form.Label>TDACS Number (Last 4)</Form.Label>
                            <Form.Control
                                required
                                placeholder='0295'
                                onChange={onTdacsChange}
                            />
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="desc">
                            <Form.Label as="legend" column sm={4}>
                                Description
                            </Form.Label>
                            <Form.Control
                                required
                                as='textarea'
                                placeholder="Leave a comment here"
                                style={{ height: '100px' }}
                                onChange={onDescChange}
                            />
                        </Form.Group>
                        <div className='ctrBtn'>
                            <Button variant="warning" type="submit" >
                                Submit
                            </Button>
                        </div>
                    </Form>
                </div>

            </animated.div>
        </>
    )
}