import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Table, Spinner, Image } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import FSSLogo from '../../images/logos/GSA-logo.jpg'


export default function Contract() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1>Märzen - GSA Contract Summary Information</h1>
                        </div>
                        <Container>
                            <Row className='paddingTop'>
                                <Col xs={12} lg={7}>
                                    <p>
                                        On 25 March 2020, Märzen Group LLC was awarded Contract Number 47QTCA20D007V by the General Services Administration (GSA). The contract includes negotiated terms of labor rates that may be used by agencies of the Federal Government. For information on the terms of eligibility for using GSA Sources of Supply and Service's refer to GSA Order ADM 4800.2E.
                                    </p>
                                </Col>
                                <Col xs={12} lg={5}><Image height='200' width='400' fluid src={FSSLogo} className='d-lg-block' alt='GSA FSS Contractor logo' /></Col>
                            </Row>

                            <Row>
                                <Col>
                                    <h2 className='h2Contract'>
                                        FEDERAL SUPPLY SERVICE,
                                        {<br />}
                                        AUTHORIZED INFORMATION TECHNOLOGY SCHEDULE PRICELIST,
                                        {<br />}
                                        GENERAL PURPOSE COMMERCIAL INFORMATION TEyCHNOLOGY,
                                        {<br />}
                                        EQUIPMENT & SOFTWARE AND SERVICES
                                    </h2>
                                    <Table borderless responsive='sm' striped>
                                        <thead>
                                            <tr>
                                                <th colSpan={2}>Contract Number: 47QTCA20D007V</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    Period of Performance: 25.MAR.2020 - 24.MAR 2025
                                                </td>
                                                <td>
                                                    Märzen Group LLC
                                                    <br />
                                                    24 Railroad Square
                                                    <br />
                                                    Nashua, NH 03060-3245
                                                    <br />
                                                    (603) 889-9522
                                                    <br />
                                                    FAX: (603) 889-9567
                                                    <br />
                                                    http://www.marzen.com
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                    <p>
                                        As part of Contract 47QTCA20D007V Märzen Group LLC has established negotiated terms and conditions and competitive labor rates in the general categories specified for Information Technology Services listed below.  The associated contractor site rates for FY2020 - 2025 are itemized in the <a href='/rates'>Märzen GSA Labor Catalog</a>.
                                    </p>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Table striped>
                                        <tbody>
                                            <tr>
                                                <td>Special Item No. 33411</td>
                                                <td>New Equipment</td>
                                            </tr>
                                            <tr>
                                                <td>Special Item No. 811212</td>
                                                <td>Equipment Maintenance</td>
                                            </tr>
                                            <tr>
                                                <td>Special Item No. 511210</td>
                                                <td>Perpetual Software Licenses</td>
                                            </tr>
                                            <tr>
                                                <td>Special Item No. 54151S</td>
                                                <td>Information Technology (IT) Professional Services</td>
                                            </tr>
                                            <tr>
                                                <td>Special Item No. OLM</td>
                                                <td>Order Level Materials</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>

                            <Row>
                                <Col className='contractNotes'>
                                    <p>NOTES:</p>
                                    <ol>
                                        <li>All non-professional labor categories must be incidental to and used solely in direct support of the professional services, and cannot be purchased separately.</li>
                                        <li>Offerors and Agencies are advised that the Group 70 – Information Technology Schedule is not to be used as a means to procure services that properly fall under the Brooks Act. These services include but are not limited to, architectural, engineering, mapping, cartographic production, remote sensing, geographic information systems, and related services. FAR 36.6 distinguishes between mapping services of an A/E nature and mapping services that are not connected nor incidental to the traditionally accepted A/E Services.</li>
                                        <li>This solicitation is not intended to solicit for the reselling of IT Professional Services, except for the provision of implementation, maintenance, integration, or training services in direct support of a product. Under such circumstances, the services must be a performance by the publisher or manufacturer or one of their authorized agents.</li>
                                    </ol>
                                    <p>Products and ordering information in this Authorized INFORMATION TECHNOLOGY Schedule Pricelist is also available on the GSA Advantage! System. Agencies can browse GSA Advantage! by clicking <a href='http://www.gsaadvantage.gov'>here</a>.</p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}