import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap'
import { useSpring, animated, config } from 'react-spring'

import employment from '../images/employ.jpg'

export default function Employment() {

    const [flip, set] = useState(false)
    const fade = useSpring({
        to: { opacity: 1 },
        from: { opacity: 0 },
        delay: 200,
        config: config.molasses,
        onRest: () => set(!flip),
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => setLoading(false), 300)
    }, [])

    return (
        <>
            {loading === false ? (
                <animated.div style={fade}>
                    <Container fluid>
                        <div className='greyHeader'>
                            <h1>Employment Opportunities</h1>
                        </div>
                        <Container>
                            <Row className='paddingTop'>
                                <Col xs={12} xl={7}>
                                    <Image height='600' width='850' src={employment} className='greyBackground' alt='A group of employees working' fluid /></Col>
                                <Col xs={12} xl={5}>
                                    <h2 className='blueBackground'>Are you motivated by the idea of making an impact?</h2>
                                    <p>
                                        Märzen Group is always looking for the highest caliber professionals.
                                        We are a highly motivated and technically oriented consulting company that treats our client’s problems with a Take-No-Prisoners attitude.
                                        Märzen anticipates having a number of employment opportunities in the next three to six months.
                                        If you have the technical credentials, professional discipline, and entrepreneurial motivation to take a leadership role in a small company and see the results of your efforts make a significant impact on the customer's competitive edge, then Märzen Group LLC is interested in talking with you!
                                    </p>
                                    <p className='greyBackground'>Please contact us at: <a href='mailto:jobs@marzen.com'>jobs@marzen.com</a></p>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                </animated.div>
            ) : (
                <Spinner animation='border' role='status' className='spinner'>
                    <span className='invisible'>Loading...</span>
                </Spinner>
            )}
        </>
    )
}